// this method allows for the copying of certain text to the clipboard
// all you have to is add an element with the following class and data attribute
// <a class="copy-me" data-clipboard-text="I Get Copied!">Click me to copy</a>
document.addEventListener("turbolinks:load", function () {
  copyMe();
});

function copyMe() {
  tippy(".copy-me", {
    trigger: "click",
    content: "Copied to the Clipboard",
    arrow: false,

    hideOnClick: false,
    onShow(instance) {
      setTimeout(() => {
        instance.hide();
      }, 2000);
    },
  });

  new ClipboardJS(".copy-me");
}
