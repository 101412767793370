$(document).on('turbolinks:load', function() {
  if ($('#mt_assessment_relation_table').length > 0) {
    getStartingAssociations();
  }
})

function getStartingAssociations() {
  let assessment_id = $('#assessment_id').val();
  $.ajax({
    url: `/super_admin/content_management/assessments/membership_template_association`,
    method: "POST",
    dataType: "json",
    data: { assessment_id },
    async: true,
    success: function(response) {
      let base_relation = response['base_relation'];
      for (let mt in base_relation) {
        if (base_relation[mt] == 1) {
          $('#mt_assessment_relation_table').find('#' + mt).empty();
          $('#mt_assessment_relation_table').find('#' + mt).append(`<span class='icon is-medium has-text-success'><i class='far fa-check-circle fa-lg'></i></span>`);
        } else if (base_relation[mt] == 2) {
          $('#mt_assessment_relation_table').find('#' + mt).empty();
          $('#mt_assessment_relation_table').find('#' + mt).append(`<span class='icon is-medium has-text-info'><i class='far fa-minus-square fa-lg'></i></span>`);
        }
      }
    },
    complete: function() {
      $('.assessment-parameters-loading-overlay').hide();
    }
  });
}