$(document).on("turbolinks:load", function () {
  $(".companies-dropdown-step-2").selectize({
    onDropdownOpen: function (str) {
      if (!this.lastQuery.length) {
        $(".selectize-dropdown-content").css("display", "none");
      } else {
        $(".selectize-dropdown-content").css("display", "block");
      }
    },
    onType: function (str) {
      if (str.length > 0) {
        $(".selectize-dropdown-content").css("display", "block");
      } else {
        $(".selectize-dropdown-content").css("display", "none");
      }
    },
  });
});

$(document).on("change", "#company_dropdown", function () {
  $("#submit_button").click();
});
