window.unveilAndInject = function(id) {
  if ($("#" + id).text().length < 4) {
    $("#" + id).append( "<p class='has-text-weight-bold'>Need help? Check out the FAQs section for help with this particular page.</p>" );
  } else {
    $("#" + id).empty();
  };
}

window.destroyParentDiv = function(div) {
  $(div).parents(".nested-fields").remove();
}

window.destroyChildDiv = function(div) {
  $(div).parents(".nested-child").remove();
}

window.minimizeRecord = function(div) {
  if ($(div).parents(".nested-fields").hasClass("csl-box-minimized-view") == false) {
    $(div).parents(".nested-fields").addClass("csl-box-minimized-view");
    $(div).parents(".nested-fields").children().find(':input').prop('disabled', true);
  } else {
    $(div).parents(".nested-fields").removeClass("csl-box-minimized-view");
    $(div).parents(".nested-fields").children().find(':input').prop('disabled', false);
  }
}

window.activateDatePicker = function() {
  // for whatever reason, sometimes flatpickr was taking longer to load.
  // so this has been refactored to check that its not null before trying to use it.
  let datePickerPast = document.getElementsByClassName("date-picker-past");
  if (datePickerPast != null && flatpickr != null) {
    datePickerPast.flatpickr({
      dateFormat: "d/m/Y",
      maxDate: new Date()
    });
  };
}


window.changeCpdSort = function(column) {
  if ($("#" + column).val().length > 0) {
    if ($("#" + column).val() == "asc") {
      $("#" + column).val("desc")
    } else {
      $("#" + column).val("asc")
    }
  } else {
    $("#" + column).val("asc")
  }
  if (column == "title_sort") {
    $("#created_sort").val(null)
  } else if (column == "created_sort") {
    $("#title_sort").val(null)
  }
  $("#cpd_form").submit();
}

window.showHideSearchAndMove = function(field, height) {
  if ($(field).css("display") == "none") {
    $("#navbar-filter").css("top", height + "px")
    $(field).show();
  } else {
    $("#navbar-filter").css("top", "-5px")
    $(field).hide();
  }

}
