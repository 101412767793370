$(document).on('turbolinks:load', function() {
  if ($('#my_notifications').length > 0) {
    markAsRead = function() {
      let notification_ids = []
      $('[id^=notification_id]').each(function() {
        notification_ids.push(this.value);
      })

      $.ajax({
        url: "/my_notifications/mark_as_read",
        method: "POST",
        dataType: "json",
        data: { notification_ids },
        async: true,
      });
    }

    if ($('[id^=notification_id]') != undefined){
      markAsRead();
    }
  };
})
