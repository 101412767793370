$(document).on('turbolinks:load', function(){
  if ($('#import_users_content').length > 0) {
    // Only show upload box when dropdowns have been populated
    $(document).on('change', '#organisation_id, #team_id', function() {
      if ($('#organisation_id').val() != undefined) {
        if ($('#organisation_id').val().length > 0 && $('#team_id').val().length > 0) {
          $('#disabled-box').hide();
          $('#dropContainer').show();
        };
      } else if ($('#team_id').val() != undefined && $('#team_id').val().length > 0) {
        $('#disabled-box').hide();
        $('#dropContainer').show();
      };
    })

    $(document).on('change', '#fileInput', function() {
      $('#import-button-2').show();
      let filename = $('#fileInput').val().replace(/C:\\fakepath\\/i, '');
      $('#box-text').text(`${filename} uploaded`);
    });

    if ($('#organisation_id').length > 0) {
      $(document).on("change", '#organisation_id', function(){
        let org_id = $(this).val();
        if(org_id.length > 0) {
          $.ajax({
            url: `/users/import_dropdown_filter`,
            method: "GET",
            dataType: "json",
            data: {org_id: org_id},
            error: function (xhr, status, error) {
              alert(`AJAX Error: ${status} ${error}`);
            },
            success: function (response) {
              getAndFilter(response);
            }
          });
        }
      });
    }
  }
});

window.importStepTwo = function(params) {
  event.preventDefault();

  var fileInput = document.querySelector('form input[type=file]');
  var file = fileInput.files[0];
  if (file == undefined) {
    alert("invaild file");
    window.location.reload();
  } else {
    var formData = new FormData();
    formData.append('file', file, 'filename.csv');
    submitUploadForm();
    $('.loading-overlay').hide();
  }

}

function generateTable(data){
  // row[5] is a boolean pass/fail for each row
  // Only show submit button if there are no failing rows present
  if (data.every(row => row[5] === true)) {
    button = `<a class="button is-primary is-pulled-right has-margin-bottom-20" alt="importandsave" href="" onclick="submitUploadForm();">Import and save users</a>`;
    $('#import-button').empty();
    $('#import-button').html(button);
  };
  $('#import-table-body').empty();
  data.forEach(row => {
    let html = "";
    row.slice(0, 5).forEach((cell, index) => {
      html += `<td style="background-color: ${ row[5] ? '#caffca' : '#ffb5b5'}">${cell}</td>`
    })
    $('#import-table-body').append(`<tr>${html}</tr>`)
  })
};

function getAndFilter(response) {
  let teams = response["teams"];
  let team_dropdown = $("#team_id")[0].selectize
  team_dropdown.setValue(null, true);
  team_dropdown.clearOptions();
  $(teams).each(function() {
    team_dropdown.addOption({value: this[0], text: this[1]});
  });
};

window.openFilePicker = function() {
  event.preventDefault();
  $('#fileInput').click();
};

window.submitUploadForm = function() {
  $('#import-step-two').removeClass("is-active");
  $('#import-step-two').addClass("is-completed");
  $('#import-step-two-icon').show();
  $('#import-step-three').addClass("is-active");
  $('.loading-overlay').show();
  $('#submit_button').click();
  //this prevent default keeps the spinning in place and lets the import complete before redirecting
  event.preventDefault();
};
