$(document).on('turbolinks:load', function(){
  $('#user_password').on('keyup', function(e){
    //if not tab
    if(e.keyCode != 9){
      var pass = $("#user_password").val();
      $("#good_pass").hide();
      $("#invalid_pass").hide();

      if(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&£_])[A-Za-z\d@$!%*?&£_]{8,}$/.test(pass)){
        $("#good_pass").show();
      }else{
        $("#invalid_pass").show();
      }
    }
  });


  if (($('#edit_profile').length == 0) && ($('#oa_user_id').length == 0) && ($('.edit_user').length == 0)) {
    $('#user_email').on('keyup', function(e){
      //if not tab
      if(e.keyCode != 9){
        if ($('#user_email').val() != "") {
          $('#user_password').prop( "disabled", true );
          $('#user_password').val("");
          $('#user_password_confirmation').prop( "disabled", true );
          $('#user_password_confirmation').val("");
          $("#good_pass").hide();
          $("#invalid_pass").hide();
        } else {
          $('#user_password').prop( "disabled", false );
          $('#user_password_confirmation').prop( "disabled", false );
        }
      }
    });
  }
});
