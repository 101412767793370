$(document).on('turbolinks:load', function(){
  if ($('#new_assessment').length > 0 || $('.edit_assessment').length > 0) {
    $(document).on("change", '#assessment_section_id', function(){
      let section_id = $(this).val();
      if(section_id.length > 0) {
        $.ajax({
          url: `/${determineControllerPath(window.location.href)}/content_management/filter_assessments_dropdowns`,
          method: "GET",
          dataType: "json",
          data: {section_id: section_id},
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            //// These lines resets the child values to avoid incongruous behavior
            if ($('#assessment_category_id').val().length > 0 || $('#assessment_procedure_id').val().length > 0) {
      	      $('#assessment_category_id')[0].selectize.setValue(null, true);
              $('#assessment_procedure_id')[0].selectize.setValue(null, true);
      	    };
            getAndFilterCategories(response);
            getAndFilterProcedures(response);
          }
        });
      }
    });

    $(document).on("change", '#assessment_category_id', function(){
      let category_id = $(this).val();
      if(category_id.length > 0) {
        $.ajax({
          url: `/${determineControllerPath(window.location.href)}/content_management/filter_assessments_dropdowns`,
          method: "GET",
          dataType: "json",
          data: {category_id: category_id},
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            $('#assessment_procedure_id')[0].selectize.setValue(null, true);
            setSection(response);
            getAndFilterCategories(response);
            getAndFilterProcedures(response);
          }
        });
      }
    });

    $(document).on("change", '#assessment_procedure_id', function(){
      let procedure_id = $(this).val();
      if(procedure_id.length > 0) {
        $.ajax({
          url: `/${determineControllerPath(window.location.href)}/content_management/filter_assessments_dropdowns`,
          method: "GET",
          dataType: "json",
          data: {procedure_id: procedure_id},
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            setSection(response);
            getAndFilterCategories(response);
            setCategory(response);
            getAndFilterProcedures(response);
          }
        });
      }
    });
  }
});

function setSection(response) {
  let section = response["section"];
  let section_dropdown = $("#assessment_section_id")[0].selectize
  section_dropdown.setValue(section[0], true);
}

function setCategory(response) {
  let category = response["category"];
  let category_dropdown = $("#assessment_category_id")[0].selectize
  category_dropdown.setValue(category[0], true);
}

function getAndFilterCategories(response) {
  let categories = response["categories"];
  let category_dropdown = $("#assessment_category_id")[0].selectize
  category_dropdown.clearOptions();
  $(categories).each(function() {
    category_dropdown.addOption({value: this[0], text: this[1]});
  });
}

function getAndFilterProcedures(response) {
  let procedures = response["procedures"];
  let procedure_dropdown = $("#assessment_procedure_id")[0].selectize
  procedure_dropdown.clearOptions();
  $(procedures).each(function() {
    procedure_dropdown.addOption({value: this[0], text: this[1]});
  });
  procedure_dropdown.close()
}
