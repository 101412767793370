document.addEventListener("turbolinks:load", () => {
  let $organisationSelector = $("#user_organisation_id");
  if ($("#user_organisation_id").length > 0) {
    hideShowLogic($organisationSelector);
    listenToOrganisationSelection($organisationSelector);
  }
});

const listenToOrganisationSelection = (orgSelect) => {
  orgSelect.change(() => {
    hideShowLogic(orgSelect);
  });
};

const hideShowLogic = (orgSelect) => {
  let selectedText = orgSelect.find(":selected").text();
  let $superAdminRole = $("#csl_admin");
  let $contributorRole = $("#contributor");
  let $orgAdminRole = $("#super_user");
  let $orgManagerRole = $("#manager");
  let $orgUserRole = $("#user");

  if (selectedText === "") {
    showElement($superAdminRole);
    showElement($contributorRole);
    hideElement($orgAdminRole);
    hideElement($orgManagerRole);
    hideElement($orgUserRole);
  } else {
    hideElement($superAdminRole);
    hideElement($contributorRole);
    showElement($orgAdminRole);
    showElement($orgManagerRole);
    showElement($orgUserRole);
  }
};

const hideElement = (element) => {
  if (!element.hasClass("is-hidden")) {
    element.addClass("is-hidden");
  }
};

const showElement = (element) => {
  element.removeClass("is-hidden");
};

$(document).on("turbolinks:load", function () {
  if ($("#user_organisation_id").length > 0) {
    let organisation_id = $("#user_organisation_id").find(":selected").val();
    $("#original_organisation_id").val(organisation_id);

    $(document).on("change", "#organisation-select", function () {
      //filter teams dropdown
      let organisation_id = $("#user_organisation_id").val();
      if (organisation_id.length > 0) {
        $.ajax({
          url: `/filter_users_dropdowns`,
          method: "GET",
          dataType: "json",
          data: { organisation_id: organisation_id },
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            //// These lines resets the child values to avoid incongruous behavior
            if ($("#team_id").val().length > 0) {
              $("#team_id")[0].selectize.setValue(null, true);
            }
            let teams = response["teams"];
            let teams_dropdown = $("#team_id")[0].selectize;
            let default_team_value = 0;
            teams_dropdown.clearOptions();
            $(teams).each(function () {
              //if default team, set default_team_value to the teams id
              if (this[2] === true) {
                default_team_value = this[0];
              }
              teams_dropdown.addOption({ value: this[0], text: this[1] });
              //set value of organisation setting
              if (
                $("#user_allow_user_role_into_multiple_teams").val() != this[3]
              ) {
                $("#user_allow_user_role_into_multiple_teams").val(this[3]);
              }
            });
            //set value of dropdown to be unassigned users
            if (default_team_value != 0) {
              teams_dropdown.setValue(default_team_value);
            }
          },
        });
      }
      //clear out table of existing rows
      $("#role_team_table_body").find("tr").remove();
      //hide table
      $("#role_team_table").hide();
      //clear out role/team ids
      $("#user_team_role_ids").val("");
      if (organisation_id.length > 0) {
        $("#team_roles_box").show();
      } else {
        $("#team_roles_box").hide();
      }
    });
  }
});

window.checkOrganisationManagerRequirements = function (button) {
  if ($("#role_team_table_body").find("tr").length == 0) {
    alert("Account must have at least one role before activating account");
    $("#role_change_button_control").addClass("is-danger-border");
    event.preventDefault();
  }
};

window.checkRoleExists = function () {
  if (
    $($($("#contributor").find("input"))[1]).is(":checked") == false &&
    $($($("#csl_admin").find("input"))[1]).is(":checked") == false &&
    $($($("#super_user").find("input"))[1]).is(":checked") == false &&
    $("#role_team_table_body").find("tr").length == 0
  ) {
    alert("Account must have at least one role before activating account");
    event.preventDefault();
  }
};

window.removeTeamRoleRow = function (button) {
  //remove team and role from values
  let old_values = $("#user_team_role_ids").val().split(",");
  let new_values = [];
  let final_values = [];

  for (var i = 0; i < old_values.length; i++) {
    if (i < 1) continue;
    if (i % 2 == 1) {
      new_values.push([old_values[i - 1], old_values[i]]);
    }
  }
  let array_to_remove = [
    $($(button).closest("tr").find("td")[0]).data().value.toString(),
    $($(button).closest("tr").find("td")[1]).data().value.toString(),
  ];
  $(new_values).each(function (i) {
    if (this.toString() != array_to_remove) {
      final_values.push(this);
    }
  });
  $("#user_team_role_ids").val(final_values);
  $(button).closest("tr").remove();
};

window.addUserTeamToTable = function () {
  //show table if hidden
  $("#role_team_table").show();
  //remove border if present
  $("#role_change_button_control").removeClass("is-danger-border");
  //create new row
  let new_row = "";
  new_row += `<tr><td data-value="${$("#user_role_id").val()}">${$(
    "#user_role_id",
  )
    .siblings()
    .find(`[data-value='${$("#user_role_id").val()}']`)
    .html()}</td><td data-value="${$("#team_id").val()}">${$("#team_id")
    .siblings()
    .find(`[data-value='${$("#team_id").val()}']`)
    .html()}</td><td><div class='bin_icon' style="cursor:pointer;" onclick="removeTeamRoleRow(this)"><div class='lid'></div><div class='lidcap'></div><div class='bin'></div></div></td></tr>`;
  let present = false;
  let allowed = true;
  //check to see if table already contains row
  if ($("#team_id").val() === "") {
    allowed = false;
    alert("Please choose a team.");
  }
  if (allowed) {
    $("#role_team_table_body")
      .find("tr")
      .each(function () {
        if (
          $($(this).find("td")[0]).data().value ==
            $($(new_row).find("td")[0]).data().value &&
          $($(this).find("td")[1]).data().value ==
            $($(new_row).find("td")[1]).data().value
        ) {
          present = true;
        } else if (
          $($(this).find("td")[1]).data().value ==
          $($(new_row).find("td")[1]).data().value
        ) {
          allowed = false;
          alert("Account can only have one role in a team.");
        } else if (
          $("#user_allow_user_role_into_multiple_teams").length > 0 &&
          $("#user_allow_user_role_into_multiple_teams").val() === "false" &&
          $($(this).find("td")[0]).data().value == 6 &&
          $($(new_row).find("td")[0]).data().value == 6
        ) {
          //check to see if the organisation allows multiple role "user" at different teams
          allowed = false;
          alert("Account can only have the role 'User' in one team.");
        }
      });
  }
  //if not, add to table and hidden field value
  if (!present && allowed) {
    if ($("#user_team_role_ids").val().length <= 0) {
      $("#user_team_role_ids").val(
        parseInt($("#user_role_id").val()) +
          "," +
          parseInt($("#team_id").val()),
      );
    } else {
      $("#user_team_role_ids").val(
        $("#user_team_role_ids").val() +
          "," +
          parseInt($("#user_role_id").val()) +
          "," +
          parseInt($("#team_id").val()),
      );
    }
    $("#role_team_table_body").append(new_row);
  }
};

window.regenerateGuestLink = function () {
  event.preventDefault();

  $.ajax({
    beforeSend: function () {
      $(".loading-overlay").show();
    },
    url: "regenerate_guest_link",
    error: function (xhr, status, error) {
      alert(`AJAX Error: ${status} ${error}`);
    },
    success: function (response) {
      $("#token_seed").val(response.new_token_seed); // For comparison in controller
      $("#guest_link_input").val(response.new_guest_link); // For display
      $(".copy-me").attr("data-clipboard-text", response.new_guest_link); // For copy to clipboard icon data
      alert(
        "Guest link regenerated. This will not take effect until the organisation is saved.",
      );
    },
    complete: function () {
      $(".loading-overlay").hide();
    },
  });
};
