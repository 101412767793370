$(document).on('turbolinks:load', () => {

  $(document).on('click', "#access-options-icon", function() {
    event.preventDefault();
    event.stopPropagation();
    $('#access-options-icon').hide();
    $('#access-options-full').show();
  })

  $(document).on('click', "#man-icon", function() {
    event.preventDefault();
    event.stopPropagation();
    $('#access-options-full').hide();
    $('#access-options-icon').show();
  })

});

window.accessOptions = function(option) {
  event.preventDefault();
  // Send changes to be saved to user on backend
  $.ajax({
    url: `/access_options`,
    method: "GET",
    dataType: "json",
    data: {option: option},
    // Set classes based on current_user values after save
    success: function (response) {
      // Highlight links
      if (response["highlight_links"]) {
        // Update toggle to match current state
        // (toggle styling is opposite in designs so true=false confusingly)
        $('#highlight_links').prop('checked', false);
        $('body').addClass('highlight_links');
      } else {
        $('#highlight_links').prop('checked', true);
        $('body').removeClass('highlight_links');
      }
      // Font size
      // Add/remove existing classes
      if (response["font_size"] == 0) {
        $('#minus-overlay-container').addClass('disabled')
        $('#plus-overlay-container').removeClass('disabled')
      } else if (response["font_size"] == 110) {
        $('#plus-overlay-container').addClass('disabled')
        $('#minus-overlay-container').removeClass('disabled')
      } else {
        $('#plus-overlay-container').removeClass('disabled')
        $('#minus-overlay-container').removeClass('disabled')
      }
      $('body').removeClass (function (index, className) {
        return (className.match (/\bfont-size-is-\d*/g) || []).join(' ');
      });
      $('body').addClass(`font-size-is-${response["font_size"]}`);
    }
  });
}
