$(document).on('turbolinks:load', function() {
  window.notificationUsers = function(notification_id, showAll) {
    event.preventDefault();
    const users = $(`#notification-users-${notification_id}`);
    const allUsers = $(`#all-notification-users-${notification_id}`);
    showAll = (showAll === 'true');  // Convert string to boolean

    if (showAll) {
      users.hide();
      allUsers.show();
    } else {
      users.show();
      allUsers.hide();
    }
  }

})
