$(document).on('turbolinks:load', () => {
  const clock = $('#countdown_timer');

  if (clock.length > 0) {
    clearInterval(window.clockInterval)
    const assessment_id = $("#assessment_id").val();
    const attempt_id = $("#attempt_id").val();
    let seconds_remaining = $('#seconds_remaining_val').val();
    localStorage.clear();
    //making it a window.clockInternal instead of const, means if i change page i can still call it and stop it
    //this prevents multiple intervals being created when swithing between questions
    window.clockInterval = setInterval(() => {
      //this checks if we are still on the same page, if we arnt then we clear interval
      if ($('#countdown_timer').length == 0) {
        clearInterval(window.clockInterval);
      }
      seconds_remaining--;
      clock.text(formatTime(seconds_remaining));

      if (seconds_remaining < 0) {
        clearInterval(window.clockInterval)
        // Submit test
        $.ajax({
          url: `/${determineControllerPath(window.location.href)}/assessments/${assessment_id}/submit_test/?attempt_id=${attempt_id}`,
          method: "get",
          error: function(xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function(response) {
            alert('You have no more time remaining. This attempt has been automatically submitted')
          }
        });
      }
    }, 1000);
  }

});

const formatTime = (secs) => {
  if (secs <= 0) {
    return "00:00"
  } else {
    let sec_num = parseInt(secs, 10)
    let hours   = Math.floor(sec_num / 3600)
    let minutes = Math.floor(sec_num / 60) % 60
    let seconds = sec_num % 60

    return [hours,minutes,seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v,i) => v !== "00" || i > 0)
      .join(":")
  }
}
