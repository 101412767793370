$(document).on('turbolinks:load', function() {
  if ($(".quicklinks").length > 0) {
    if ($(".organisation_section_quicklinks").length <= 0) {
      $("#organisation_section_title").remove();
    }
    if ($(".organisation_category_quicklinks").length <= 0) {
      $("#organisation_category_title").remove();
    }
    if ($(".organisation_procedure_quicklinks").length <= 0) {
      $("#organisation_procedure_title").remove();
    }
    if ($(".personal_section_quicklinks").length <= 0) {
      $("#personal_section_title").remove();
    }
    if ($(".personal_category_quicklinks").length <= 0) {
      $("#personal_category_title").remove();
    }
    if ($(".personal_procedure_quicklinks").length <= 0) {
      $("#personal_procedure_title").remove();
    }
    if (($("#personal_procedure_title").length <= 0) && ($("#personal_category_title").length <= 0) && ($("#personal_section_title").length <= 0) && ($("#organisation_procedure_title").length <= 0) && ($("#organisation_category_title").length <= 0) && ($("#organisation_section_title").length <= 0) && ($("#latest_updates_title").length <= 0)) {
      $(".quicklinks").parent().remove();
      $(".column").removeClass("is-10");
    }
  }
})
