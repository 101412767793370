let removeManualArray = [];
let startingPositions = {};
let endPositions = {};

$(document).on('turbolinks:load', function(){
  if ($('#questions-summary-table').length > 0) {
    $('#summary_sortable_rows').sortable();
    getStartingPositions();
  }
});

function getStartingPositions() {
  let index = 1;
  $('tr.summary_row').each(function(){
    startingPositions[$(this).attr('data-id')] = index;
    index += 1
  })
}

window.removeManualQuestion = function(question) {
  removeManualArray.push(question.id);
  $('#removed_manual_question_ids').val(removeManualArray);
  $('[data-id="'+question.id+'"]').remove();
}

window.getEndPositions = function() {
  let index = 1;
  $('tr.summary_row').each(function(){
    endPositions[$(this).attr('data-id')] = index;
    index += 1
  })
  positionChanges = diff(endPositions, startingPositions);
  $('#question_positions').val(JSON.stringify(positionChanges));
}

function diff(obj1, obj2) {
  final = {}
  for (let i in obj1) {
    if (obj1[i] !== obj2[i]) {
      final[i] = obj1[i]
    }
  }
  return final;
}