let assignments = [];
let membership_template_ids = [];

$(document).on('turbolinks:load', function(){
  if ($("#membership-templates-table").length > 0) {
    getMembershipTemplateIds();
    mt_sec_associations();
  } else if ($('#no_membership_templates').length > 0) {
    $(".loading-overlay").hide();
  }
});

// passes the membership template ids over to the sections controller 
// the response comes back with all the associations between the sent membership templates and every section in the system
function mt_sec_associations() {
  $.ajax({
    url: `/super_admin/content_management/sections/starting_associations`,
    method: "POST",
    dataType: "json",
    data: {membership_template_ids},
    async: true,
    success: function (response) {
      assignments = response['data'][0];
      fillCells();
    }
  });
}

// uses the assignments array to loop through and for every item, append a span, based on the value, to the td where the id is the items key
function fillCells() {
  for (const [key, value] of Object.entries(assignments)) {
    if (value == 1) {
      $('td#' + key).append(`<span class='icon is-medium has-text-success'><i class='far fa-check-circle fa-lg'></i></span>`);
    } else if (value == 2) {
      $('td#' + key).append(`<span class='icon is-medium has-text-info'><i class='far fa-minus-square fa-lg'></i></span>`);
    } else {
      $('td#' + key).append("<span class='icon is-medium has-text-danger'><i class='far fa-times-circle fa-lg'></i></span>");
    }
  }
  $(".loading-overlay").hide();
}

// populates the array "membership_template_ids" with only the mt_ids of the current mt displayed on the page
function getMembershipTemplateIds() {
  $('#membership-templates-table td[id^="mt_"]').each(function() {
    membership_template_ids.push(this.id.split('_')[1]);
  });
}
