let lastSortedField = null;
let sortAsc = true;

function sortTable(field, ascOrderFieldId, iconId, isDate, isPercentage) {
  event.preventDefault();

  // Check if the same column has been clicked again. If so, toggle the sort direction
  if (lastSortedField === field) {
    sortAsc = !sortAsc; // Toggle the sort direction
  } else {
    // If a new column has been clicked, set the sort to ascending
    sortAsc = true;
  }

  // Update the last sorted field
  lastSortedField = field;

  // Get the current state from the hidden field
  let sortAsc = $(`#${ascOrderFieldId}`).val() === "asc";
  const $table = $('#attempts-table');
  let $rows = $table.find('.data-row');

  let indices = $rows.map(function(index, row) {
    let textValue;
    switch (field) {
      case 'title':
        textValue = $('.title-cell', row).text();
        break;
      case 'date_taken':
        textValue = $('.date-taken-cell', row).text();
        break;
      case 'expires':
        textValue = $('.expires-cell', row).text();
        break;
      case 'prompt_date':
        textValue = $('.prompt-date-cell', row).text();
        break;
      case 'pass_mark':
        textValue = $('.pass-mark-cell', row).text();
        break;
      case 'my_score':
        textValue = $('.my-score-cell', row).text();
        break;
      case 'result':
        textValue = $('.result-cell', row).text();
        break;
      case 'status':
        textValue = $('.status-cell', row).text();
        break;
      default:
        console.log("Invalid field: " + field);
        textValue = "";
    }

    if (isDate && textValue !== "N/A") {
      let [date, time] = textValue.split(" ");
      let [day, month, year] = date.split("/").map(Number);
      let [hour, minute] = time.split(":").map(Number);
      textValue = new Date(year, month - 1, day, hour, minute);
    }
    if (isPercentage) {
      // convert percentage to number or N/A to start/end
      textValue = textValue === "N/A" ? (sortAsc ? -Infinity : Infinity) : parseFloat(textValue.replace('%', ''));
    }
    return {
      index: index,
      text: textValue
    };
  }).get();

  indices.sort(function (a, b) {
    // Handle 'Infinity' values assigned to 'N/A' in percentage columns
    if (a.text === Infinity) return 1;
    if (b.text === Infinity) return -1;
    if (a.text === -Infinity) return -1;
    if (b.text === -Infinity) return 1;

    // If both are 'N/A', they are equal in terms of sorting.
    if (a.text === "N/A" && b.text === "N/A") {
      return 0;
    }

    // If 'a' is 'N/A', it should be sorted based on the ascending flag.
    if (a.text === "N/A") {
      return sortAsc ? -1 : 1;
    }

    // If 'b' is 'N/A', it should be sorted based on the ascending flag.
    if (b.text === "N/A") {
      return sortAsc ? 1 : -1;
    }

    // Compare numbers for date and percentage after ensuring they are not 'N/A'
    if (isPercentage || (isDate && !isNaN(a.text) && !isNaN(b.text))) {
      return sortAsc ? a.text - b.text : b.text - a.text;
    }

    // Compare strings for non-date and non-percentage fields
    if (typeof a.text === 'string' && typeof b.text === 'string') {
      return sortAsc ? a.text.localeCompare(b.text) : b.text.localeCompare(a.text);
    }

    // Fallback for any other unforeseen data types
    return 0;
  });


  $.each(indices, function(index, obj) {
    $table.append($rows[obj.index]);
  });

  // Toggle the sort order and save it back to the hidden field
  sortAsc = !sortAsc;
  $(`#${ascOrderFieldId}`).val(sortAsc ? "asc" : "desc");

  // setTimeout moves this to the end of the JS event queue, allowing the sorting to finish before the icon is changed
  setTimeout(function() {
    $(`#${iconId}`).removeClass("fa-minus fa-chevron-down fa-chevron-up")
    $(`#${iconId}`).addClass(sortAsc ? "fa-chevron-down" : "fa-chevron-up");
  }, 0);
}

$(document).on('turbolinks:load', function(){

  window.sortPerformanceBy = function sortPerformance(field) {
    switch (field) {
      case 'title':
        sortTable("title", "title_sort_order", "title_sort_icon");
        break;
      case 'date_taken':
        sortTable("date_taken", "date_taken_sort_order", "date_taken_sort_icon", true);
        break;
      case 'expires':
        sortTable("expires", "expires_sort_order", "expires_sort_icon", true);
        break;
      case 'prompt_date':
        sortTable("prompt_date", "prompt_date_sort_order", "prompt_date_sort_icon", true);
        break;
      case 'pass_mark':
        sortTable("pass_mark", "pass_mark_sort_order", "pass_mark_sort_icon", false, true);
        break;
      case 'my_score':
        sortTable("my_score", "my_score_sort_order", "my_score_sort_icon", false, true);
        break;
      case 'result':
        sortTable("result", "result_sort_order", "result_sort_icon");
        break;
      case 'status':
        sortTable("status", "status_sort_order", "status_sort_icon");
        break;
      default:
        console.log("Invalid field: " + field);
    }
  }

  window.performanceSearch = function() {
    event.preventDefault();
    $("#performance-search-submit").click();
  }

});
