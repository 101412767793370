document.addEventListener("turbolinks:load", () => {
  if ($(".file-input").length) {
    setFileUploadText();
    handleSingleFileDelete();
    handleMultipleUploadDelete();
  }

  $(document).on('change', 'input[type="file"]', function(event) {
    let current = this;
    let files = event.target.files;
    // This is to prevent duplicate function calls
    if (event.handled !== true) {
      event.handled = true;
      checkFileNames(current, files)
      previewFiles(current, files);
    }
  });
});

const checkFileNames = (current, files) => {
  Object.values(files).forEach((file) => {
    let invalid_file = false
    //determine wether the filename includes any characters it shoudnt.
    file_name = file.name.toLowerCase().replace(/a|b|c|d|e|f|g|h|i|j|k|l|m|n|o|p|q|r|s|t|u|v|w|x|y|z|1|2|3|4|5|6|7|8|9|0|_|-| /g, '')
    //character '.' needs to be done seperately, if included in the above query it nukes out the string completely
    file_name = file_name.replaceAll('.', '')
    //if there are characters left, theyre invalid
    if (file_name.length > 0) {
      //alert user
      alert("File name not supported, File name can only include underscores, hyphens, dots, spaces and alphanumerics");
      //nuke out existing uploaded images and their previews
      if ($(current).parents('.box').find('#multi-preview').length > 0) {
        $(current).parents('.box').find('#multi-preview').empty();
      } else {
        $(current).parents('.box').find('#single-preview').empty();
      }
      //nuke out the input values (what user doesnt see)
      document.getElementById(current.id).value = "";
      //nuke out the input text (what user see's)
      current.parentNode.getElementsByClassName("file-name")[0].innerHTML = ""
    }
  })
}

const previewFiles = (current, files) => {
  // Loop over uploaded objects and generate preview for each
  Object.values(files).forEach((file) => {
    let reader = new FileReader();
    reader.onload = function(file) {
      let img = `
        <div class="field pb-0 column is-3 multiple-file-display">
          <figure class="image is-128x128">
            <span class="has-text-info remove-attachment"><i class='fas fa-cloud-upload-alt'></i></span>
            <img class="preview" src="${file.target.result}">
          </figure>
        </div>
        `;
      // Determine if a single or multiple upload button was clicked
      // and either append or completely replace the HTML accordingly
      if ($(current).parents('.box').find('#multi-preview').length > 0) {
        $(current).parents('.box').find('#multi-preview').append(img);
      } else {
        $(current).parents('.box').find('#single-preview').html(img);
      }
    }
    reader.readAsDataURL(file);
  })
}

const setFileUploadText = () => {
  $(".file-input").change((e) => {
    if (e.target.files.length === 1) {
      $(e.target).siblings(".file-name").text(e.target.files[0].name);
    } else if (e.target.files.length > 1) {
      let fileNames = $(e.target.files).map((index, element) => element.name).get().join(", ");
      $(e.target).siblings(".file-name").text(fileNames);
    }
  });
}

const handleSingleFileDelete = () => {
  $(".single-file-delete").click((e) => {
    $(e.target).closest(".single-file-display").hide();
    $(e.target).closest(".box").find(".file-name").text("");
  });
}

const handleMultipleUploadDelete = () => {
  $(".multiple-file-delete").click((e) => {
    $(e.target).closest(".multiple-file-display").hide();

    let $fileNameElement = $(e.target).closest(".box").find(".file-name");
    let fileNameToRemove = $(e.target).parent().data().fileName;
    let updatedFileNameText = $fileNameElement.text().split(`${fileNameToRemove}, `).join("");
    let updatedFileNameTextTwo = updatedFileNameText.split(`${fileNameToRemove}`).join("");
    $fileNameElement.text(updatedFileNameTextTwo);
  });
}
