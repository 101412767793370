// this js file will allow us to hide and remove functionality
// from the trix editor we don't need
$(document).on('turbolinks:load', () => {
  if (
    $("form.edit_user").length || 
    $("form.new_global_note").length ||
    $("form.edit_global_note").length || 
    $("form.new_additional_info").length ||
    $("form.edit_additional_info").length
  ) {
    removeAttachmentFeature();
  }
});

const removeAttachmentFeature = () => {
  window.addEventListener("trix-file-accept", (e) => {
    e.preventDefault();
    alert("File attachment not supported!");
  })
  $(".trix-button-group--file-tools").hide();
}
