$(document).on('turbolinks:load', () => {
  $(document).on('click', "#quicklink-toggle", function() {
    $(this).closest('form').submit();
  })

  $(document).on('click', "#register-with-toggle", function() {
    if ($('#register_with').is(':checked')) {
      $('#register_with_password').removeClass('has-text-grey');
      $('#register_with_email').addClass('has-text-grey');
      $('#email_field').hide();
      $('#password_field').show();
    } else {
      $('#register_with_email').removeClass('has-text-grey');
      $('#register_with_password').addClass('has-text-grey');
      $('#email_field').show();
      $('#password_field').hide();
    }
  })
});
