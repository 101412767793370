$(document).on('turbolinks:load', function(){
  // this method will be a generic method for handling any modal on any page
  // it will however not be able to handle multiple modals on one page
  // all you need to do is: add the ID `modal-activator` to the element you want to activate the modal when clicked
  handleModal();
});

function handleModal() {
  $(document).on('click', "#modal-activator", function(e) {
    //activate modal
    $("#active-modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-masquerade", function(e) {
    $("#user_role")[0].selectize.clear();
    $("#team")[0].selectize.clearOptions();
    $("#team")[0].selectize.clear();
    $("#team-dropdown").css("display","none");
    $("#selected_organisation_id").val($(e.currentTarget).data("id"))
    $("#masquerade-modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-global-note", function(e) {
    $("#global-note-modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-org-note", function(e) {
    $("#org-note-modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-personal-note", function(e) {
    $("#personal-note-modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-save-user", function(e) {
    e.preventDefault();
    $("#save-user-modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-submit-attempt", function(e) {
    e.preventDefault();
    $("#submit-attempt-modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-tandc", function(e) {
    e.preventDefault();
    $("#tandc_modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-policy", function(e) {
    e.preventDefault();
    $("#policy_modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-accessability", function(e) {
    e.preventDefault();
    $("#accessability_modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-cookies", function(e) {
    e.preventDefault();
    $("#cookies_modal").addClass("is-active");
  });

  $(document).on('click', "#modal-activator-contact", function(e) {
    e.preventDefault();
    $("#contact_modal").addClass("is-active");
  });

  $(document).on('click', ".modal-background, #modal-close", function(e) {
    //close modal
    e.preventDefault();
    $(".modal").removeClass("is-active");
  });
}

window.showQuestionsRevisionNotesModal = function(question_id) {
  $('#revision_notes_modal').addClass("is-active");
  $('#chosen_question_id').val(question_id);
}

window.showQuestionsRevisionNotesMultipleModal = function() {
  $('#revision_notes_muiltiple_modal').addClass("is-active");
}

window.showDeleteQuestionRevisionNotesModal = function(question_id, path) {
  if (confirm("Are you sure you want to delete this question?")) {
    $('#delete_question_revision_notes_modal').addClass("is-active");
    $('#delete_chosen_question_id').val(question_id);
  }
}

window.showCreateQuestionRevisionNotesModal = function(question_id, path) {
  $('#create_question_revision_notes_modal').addClass("is-active");
  $('#create_chosen_question_id').val(question_id);
}
