document.addEventListener("turbolinks:load", function() {
  let selected_ids = []

  window.selectMultiple = function(checkbox) {
    // when a checkbox is selected, we add it to the select_ids array
    if (checkbox.checked === true) {
      selected_ids.push(checkbox.value)
    } else {
      for( let i = 0; i < selected_ids.length; i++){
        if ( selected_ids[i] === checkbox.value) {
          selected_ids.splice(i, 1);
        }
      }
    }
    $("#selected_checkboxes").val(selected_ids);
    if ($("#select_all_notification") != null) { $("#select_all_notification").css("display", "none") }
    if ($("multi_select_actions") != null && selected_ids.length === 0 ) {
      $("#multi_select_actions").css("display", "none")
    } else if ($("multi_select_actions") != null ) {
      $("#multi_select_actions").css("display", "block")
    }
  }

  window.selectAll = function(checkbox) {
    // if the table checkbox is ticked, we select all on that page, then we add it to selected_ids array
    if (checkbox.checked) {
      if ($("#select_all_notification") != null) { $("#select_all_notification").css("display", "block") }
      if ($("multi_select_actions") != null) { $("#multi_select_actions").css("display", "block") }
    } else {
      if ($("#select_all_notification") != null) { $("#select_all_notification").css("display", "none") }
      if ($("#confirm_select_all_notification") != null) { $("#confirm_select_all_notification").css("display", "none") }
      if ($("multi_select_actions") != null) { $("#multi_select_actions").css("display", "none") }
    }
    checkboxes = $(".checkbox");
    check_or_uncheck(checkbox);
    $("#selected_checkboxes").val(selected_ids);
  };

  window.selectAllLink = function() {
    // this is called when the select all over multiple pages (from the notification box)
    event.preventDefault();
    if ($("#select_all_notification") != null) { $("#select_all_notification").css("display", "none") }
    if ($("#confirm_select_all_notification") != null) { $("#confirm_select_all_notification").css("display", "block") }
    $("#selected_checkboxes").val("all");
    selected_ids = []
  };

  function check_or_uncheck(checkbox) {
    // figures out wether a checkbox is ticked or unticked, used on the select all table checkbox
    if ( checkbox.checked ) {
      for (let i = 0; i < checkboxes.length; i++) {
        let checkbox = checkboxes[i];
        checkbox.checked = true
        if (selected_ids.includes(checkbox.value) == false) {
          selected_ids.push(checkbox.value)
        }
      }
    } else {
      for (let i = 0; i < checkboxes.length; i++) {
        let checkbox = checkboxes[i];
        checkbox.checked = false
        for( let o = 0; o < selected_ids.length; o++){
          if ( selected_ids[o] === checkbox.value ) {
            selected_ids.splice(o, 1);
          }
        }
      }
    }
  };

  window.deselectAll = function() {
    // this just cleans up the selected ids when they click clear/enter search
    selected_ids = []
  };

  window.checkSelected = function() {
    // this is called when pagination is used, it checks certain checkboxes
    $(".select_multiple_table").bind("DOMSubtreeModified",function(){
      if ($("#select_all_notification") != null) { $("#select_all_notification").css("display", "none") }
      if ($("#confirm_select_all_notification") != null) { $("#confirm_select_all_notification").css("display", "none") }
      for( let i = 0; i < selected_ids.length; i++){
        check_box = document.getElementById("checkbox_" + selected_ids[i]);
        if (check_box !== null){
          check_box.checked = true;
        }
      }
    });
  }
});
