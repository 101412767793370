let final_positions = {};

$(document).on('turbolinks:load', function() {
  if ($('#main_content_positions').length > 0) {
    $('#sortable_main_content').sortable();
  }
});

window.getFinalPositions = function() {
  let index = 1;
  $('.sortable_procedure_pdf').each(function() {
    final_positions[$(this).attr('data-id')] = index;
    index += 1
  })
  $('#main_content_positions').val(JSON.stringify(final_positions));
}

window.promptSave = function() {
  $('#prompt_save_modal').addClass("is-active");
}