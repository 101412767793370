const Trix = require('trix');

// Custom Trix buttons

// Add new html tag
Trix.config.textAttributes.underline = {
  tagName: "u",
  inheritable: true

  // Example of how to add custom css in cases where a suitable html tag doesn't exist:
  
  //style: { textDecoration: "underline" },
  //parser: function(element) {
  //return element.style.textDecoration === "underline"
  //},
  //inheritable: true 
}

// Add new buttton to Trix toolbar
// 'data-trix-key' defines an optional keyboard shortcut, <C-u>/<CMD-u> in this case
addEventListener("trix-initialize", function(event) {
  let buttonHTML = '<button type="button" class="trix-button" style="font-size: inherit; font-weight: 700; text-decoration: underline" data-trix-attribute="underline" data-trix-key="u">U</button>'

  event.target.toolbarElement.
  querySelector(".trix-button-group").
  insertAdjacentHTML("beforeend", buttonHTML)
})

window.checkCharCount = function(text, limit, warning, button) {
  let char_count = $(text).text().length
  if (char_count > limit) {
    $(warning).show()
    $(button).hide()
  } else {
    $(warning).hide()
    $(button).show()
  }
}

// Update trix content on user bio edit *before* saving
window.changeBio = function() {
  event.preventDefault();
  let content = $('#user_bio').html();
  let char_count = $('#user_bio').text().length;
  $.ajax({
    url: 'change_bio',
    method: "POST",
    data: { 
      user_id: $('#user_id').val(),
      content: content,
      char_count: char_count
    },
    error: function(xhr, status, error) {
      alert(`AJAX Error: ${status} ${error}`);
    },
    success: function(response) {
      if (response["saved"]) {
        $('#bio_field_edit').hide();
        $('#bio-content').html(content);
        $('#bio_field_preview').show();
      } else {
        alert('Sorry, bio could not be saved because it is above the permitted character limit.');
      }
    }
  });
}
