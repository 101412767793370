let user_id_array = [];

$(document).on("turbolinks:load", function () {
  if ($("#notification_team_ids").length > 0) {
    if (
      $("#notification_team_ids").val() !== null &&
      $("#notification_team_ids").val().length > 0
    ) {
      $("#notification_user_selection").show();
      $.ajax({
        url: `/${determineControllerPath(window.location.href)}/filter_notification_users`,
        method: "GET",
        dataType: "json",
        data: { team_id: $("#notification_team_id").val() },
        error: function (xhr, status, error) {
          alert(`AJAX Error: ${status} ${error}`);
        },
        success: function (response) {
          getAndFilterUsers(response);
        },
      });
    } else {
      $("#notification_user_selection").hide();
    }
    $("#notification_team_ids").on("change", function () {
      let team_ids = $(this).val();
      if (team_ids !== null && team_ids.length > 0) {
        $("#notification_user_selection").show();
        $.ajax({
          url: `/${determineControllerPath(window.location.href)}/filter_notification_users`,
          method: "GET",
          dataType: "json",
          data: { team_ids: team_ids },
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            // These lines reset the child values to avoid incongruous behavior
            user_ids = $("#notification_user_ids").val();
            if (user_ids != null) {
              $("#notification_user_ids")[0].selectize.setValue(null, true);
            }
            getAndFilterUsers(response);
            if (user_ids != null) {
              user_ids.forEach(function (user_id) {
                if (user_id_array.includes(Number(user_id))) {
                  $("#notification_user_ids")[0].selectize.addItem(
                    Number(user_id),
                    true,
                  );
                }
              });
            }
          },
        });
      } else {
        $("#notification_user_ids")[0].selectize.setValue(null, true);
        $("#notification_user_ids")[0].selectize.clearOptions();
        $("#notification_user_selection").hide();
      }
    });
  }
});

function getAndFilterUsers(response) {
  let users = response["users"];
  let users_dropdown = $("#notification_user_ids")[0].selectize;
  user_id_array = [];
  users_dropdown.clearOptions();
  $(users).each(function () {
    user_id_array.push(this[0]);
    users_dropdown.addOption({ value: this[0], text: this[1] });
  });
}
