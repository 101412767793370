$(document).on('turbolinks:load', () => {
  // Second parameter is the target to be revealed by checkbox
  window.checkboxShow = function(checkbox, field) {
    // If checked show target input field
    if ($(checkbox).prop('checked')) {
      $(field).show()
    } else
      $(field).hide();
      // Reset field value on form if user unticks (unless target is a button)
      if (!/button/.test($(field).find("input").attr("class"))) {
        ($(field).find("input").val("nil"))
      }
  }

  // Same as above but using a link, preventDefault to stop page reload
  // Optional second parameter for a target to hide at the same time
  window.showField = function(field, target_to_hide = null) {
    event.preventDefault();
    $(field).show();
    if (target_to_hide != null) {
      $(target_to_hide).hide();
    }
  }
});
