$(document).on('turbolinks:load', function(){

  const showSlider = function(count, target) {
    if (count > 0) {
      // determine screen width and divide by 360 (width of columns)
      let screen_available = Math.floor(window.innerWidth / 360);
      let show_slider = true;
      if (count < screen_available) {
        // if procedures count is less than the available screen width, dont show slider
        show_slider = false
      };
      if (show_slider == true) {
        const options = {
          slidesToScroll: 1,
          slidesToShow: 1,
          infinite: true,
          autoplay: true,
          pauseOnHover: true,
        };
        if ($(target) != undefined) {
          // Sliders need to be instantiated separately, create a second variable if one already exists
          // This method will obviously only deal with a maximum of two sliders unless more elsifs are added
          if (typeof slider !== 'undefined') {
            var slider = bulmaCarousel.attach(target, options);
          } else {
            var second_slider = bulmaCarousel.attach(target, options);
          }
        };
      };
    };
  }

  if ($('#related_procedure_count') != undefined) {
    //on load, show slider
    showSlider($('#related_procedure_count').val(), '#related_slider')
    $(window).on('resize', function(){
      //on resize, adjust slider
      showSlider($('#related_procedure_count').val(), '#related_slider')
    })
  }
  if ($('#latest_procedure_count') != undefined) {
    //on load, show slider
    showSlider($('#latest_procedure_count').val(), '#latest_slider')
    $(window).on('resize', function(){
      //on resize, adjust slider
      showSlider($('#latest_procedure_count').val(), '#latest_slider')
    })
  }

});
