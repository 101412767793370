import Cropper from 'cropperjs';

document.addEventListener("turbolinks:load", function() {
  var image = document.getElementById('image');
  var input = document.getElementById('input');
  var modal = document.getElementById('modal');
  var close = document.getElementById('close');
  var cancel = document.getElementById('cancel');
  var cropper;

  if (input != null) {
    input.addEventListener('change', function (e) {
      var files = e.target.files;
      var done = function (url) {
        input.value = '';
        image.src = url;
        modal.classList.add("is-active");
        cropper = new Cropper(image, {
          aspectRatio: 1,
          viewMode: 0,
        });
      };
      var reader;
      var file;
      var url;

      if (files && files.length > 0) {
        file = files[0];

        if (URL) {
          done(URL.createObjectURL(file));
        } else if (FileReader) {
          reader = new FileReader();
          reader.onload = function (e) {
            done(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
    });

    document.getElementById('crop').addEventListener('click', function () {
      var canvas;

      modal.classList.remove("is-active");

      if (cropper) {
        canvas = cropper.getCroppedCanvas({
          width: 160,
          height: 160,
        });
        canvas.toBlob(function (blob) {
          $.ajax({
            url: 'upload_avatar',
            method: "POST",
            data: { user_id: $('#user_id').val(), image: canvas.toDataURL(), filetype: blob.type },
            error: function(xhr, status, error) {
              alert(`AJAX Error: ${status} ${error}`);
            },
            success: function() {
              $('#current_avatar').hide()
              $('#new_avatar_img').attr("src", canvas.toDataURL());
              $('#new_avatar').css('display', 'flex');
            }
          });
        });
      }
    });

    cancel.onclick = function () {
      window.location.reload();
    };
    close.onclick = function () {
      window.location.reload();
    };
  }

});
