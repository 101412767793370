$(document).on('turbolinks:load', () => {
  $("#sortable").sortable({
    helper: fixWidthHelper,
    scroll: true,
    scrollSensitivity: 80,
    scrollSpeed: 1,
    sort: function(event, ui) {
      var currentScrollTop = $(window).scrollTop(),
        topHelper = ui.position.top,
        delta = topHelper - currentScrollTop;
      setTimeout(function() {
        if(delta > 7) delta = 4;
        $(window).scrollTop(currentScrollTop + delta);
      }, 5);
    },
    update: function (e, ui) {
      $.ajax({
        url: $(this).data("url"),
        type: "PUT",
        data: $(this).sortable("serialize")
      });
    }

  });
});

function fixWidthHelper(e, ui) {
  ui.children().each(function() {
      $(this).width($(this).width());
  });
  return ui;
}