$(document).on('turbolinks:load', function(){
  // only do this if your a super admin (checking if super_admin is in url)
  if ($('#question_search').length > 0 || $('#assessment_search').length > 0) {
    url = `/${determineControllerPath(window.location.href)}/content_management/questions/filter_questions_dropdowns`
    if ($('#assessment_search').length > 0) {
      url = url + `?assessment=true`
    };
    $(document).on("change", '#q_question_banks_section_id_eq', function(){
      let section_id = $(this).val();
      if(section_id.length > 0) {
        $.ajax({
          url: url,
          method: "GET",
          dataType: "json",
          data: {section_id: section_id, filtered_question_bank_id: $('#question_banks_id_eq').val()},
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            // These lines resets the child values to avoid incongruous behavior
            if ($('#q_question_banks_id_eq').length > 0) {
              if ($('#q_question_banks_id_eq').val().length > 0) {
                $('#q_question_banks_id_eq')[0].selectize.setValue(null, true);
              };
              getAndFilterQuestionBanks(response);
            }
            if ($('#q_question_banks_category_id_eq').val().length > 0 || $('#q_question_banks_procedures_id_eq').val().length > 0) {
              $('#q_question_banks_category_id_eq')[0].selectize.setValue(null, true);
              $('#q_question_banks_procedures_id_eq')[0].selectize.setValue(null, true);
            };
            getAndFilterCategories(response);
            getAndFilterProcedures(response);
          }
        });
      }
    });

    $(document).on("change", '#q_question_banks_category_id_eq', function(){
      let category_id = $(this).val();
      if(category_id.length > 0) {
        $.ajax({
          url: url,
          method: "GET",
          dataType: "json",
          data: {category_id: category_id, filtered_question_bank_id: $('#question_banks_id_eq').val()},
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            // These lines resets the child values to avoid incongruous behavior
            if ($('#q_question_banks_id_eq').length > 0) {
              if ($('#q_question_banks_id_eq').val().length > 0) {
                $('#q_question_banks_id_eq')[0].selectize.setValue(null, true);
              };
              getAndFilterQuestionBanks(response);
            }
            if ($('#q_question_banks_procedures_id_eq').val().length > 0) {
              $('#q_question_banks_procedures_id_eq')[0].selectize.setValue(null, true);
            };
            setSection(response);
            getAndFilterCategories(response);
            getAndFilterProcedures(response);
          }
        });
      }
    });

    $(document).on("change", '#q_question_banks_procedures_id_eq', function(){
      let procedure_id = $(this).val();
      if(procedure_id.length > 0) {
        $.ajax({
          url: url,
          method: "GET",
          dataType: "json",
          data: {procedure_id: procedure_id, filtered_question_bank_id: $('#question_banks_id_eq').val()},
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            if ($('#q_question_banks_id_eq').length > 0 && $('#q_question_banks_id_eq').val().length > 0) {
              $('#q_question_banks_id_eq')[0].selectize.setValue(null, true);
            };
            setSection(response);
            getAndFilterCategories(response);
            setCategory(response);
            getAndFilterProcedures(response);
          }
        });
      }
    });

    $(document).on("change", '#q_question_banks_id_eq', function(){
      let question_bank_id = $(this).val();
      if(question_bank_id.length > 0) {
        $.ajax({
          url: `/${determineControllerPath(window.location.href)}/content_management/questions/filter_questions_dropdowns`,
          method: "GET",
          dataType: "json",
          data: {question_bank_id: question_bank_id, filtered_question_bank_id: $('#question_banks_id_eq').val()},
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            if ($('#q_question_banks_procedures_id_eq').length > 0 && $('#q_question_banks_procedures_id_eq').val().length > 0) {
              $('#q_question_banks_procedures_id_eq')[0].selectize.setValue(null, true);
            };
            setSection(response);
            setCategory(response);
            getAndFilterQuestionBanks(response);
          }
        });
      }
    });
  }
});

function setSection(response) {
  let section = response["section"];
  let section_dropdown = $("#q_question_banks_section_id_eq")[0].selectize
  section_dropdown.setValue(section[0], true);
}

function setCategory(response) {
  let category = response["category"];
  let category_dropdown = $("#q_question_banks_category_id_eq")[0].selectize
  category_dropdown.setValue(category[0], true);
}

function getAndFilterCategories(response) {
  let categories = response["categories"];
  let category_dropdown = $("#q_question_banks_category_id_eq")[0].selectize
  category_dropdown.clearOptions();
  $(categories).each(function() {
    category_dropdown.addOption({value: this[0], text: this[1]});
  });
}

function getAndFilterProcedures(response) {
  let procedures = response["procedures"];
  let procedure_dropdown = $("#q_question_banks_procedures_id_eq")[0].selectize
  procedure_dropdown.clearOptions();
  $(procedures).each(function() {
    procedure_dropdown.addOption({value: this[0], text: this[1]});
  });
}

function getAndFilterQuestionBanks(response) {
  let question_banks = response["question_banks"];
  let question_banks_dropdown = $("#q_question_banks_id_eq")[0].selectize
  question_banks_dropdown.clearOptions();
  $(question_banks).each(function() {
    if ($("#existing_question_bank").length > 0 && $("#existing_question_bank").val() != this[0]) {
      question_banks_dropdown.addOption({value: this[0], text: this[1]});
    } else if (!$("#existing_question_bank").length > 0) {
      question_banks_dropdown.addOption({value: this[0], text: this[1]});
    }
  });
}
