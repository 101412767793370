window.confirm_allocation = function() {
  if (!confirm("Are you sure? This question is already in use on another question bank. Allocating it here will remove this relationship.")) {
    event.preventDefault();
  }
}

window.no_allocation = function() {
  if (!confirm("Are you sure? This question is already in use on another question bank. This will remove this relationship.")) {
    event.preventDefault();
  }
}
