$(document).on("turbolinks:load", function () {
  if (
    ($("#user_search").length > 0 &&
      $("#filter_dropdowns_url").val().length > 0) ||
    $("#users_submit_search").length > 0
  ) {
    var fields = [
      "#q_first_name_cont",
      "#q_last_name_cont",
      "#q_email_cont",
      "#q_username_cont",
      "#q_organisation_id_eq",
      "#q_role_user_relationships_team_id_eq",
      "#q_role_user_relationships_user_role_name_eq",
    ];

    if (
      fields.some(function (field) {
        return $(field).val() !== "";
      })
    ) {
      $("#users_submit_search").click();
    }
    $(document).on("change", "#q_organisation_id_eq", function () {
      let organisation_id = $(this).val();
      if (organisation_id.length > 0) {
        $("#users_teams").removeClass("is-hidden");
        $.ajax({
          url: `/filter_users_dropdowns`,
          method: "GET",
          dataType: "json",
          data: { organisation_id: organisation_id },
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            //// These lines resets the child values to avoid incongruous behavior
            if (
              $("#q_role_user_relationships_team_id_eq").val().length > 0 ||
              $("#q_role_user_relationships_user_role_name_eq").val().length > 0
            ) {
              $(
                "#q_role_user_relationships_user_role_name_eq",
              )[0].selectize.setValue(null, true);
              $("#q_role_user_relationships_team_id_eq")[0].selectize.setValue(
                null,
                true,
              );
            }
            getAndFilterTeams(response);
          },
        });
      }
    });

    $(document).on(
      "change",
      "#q_role_user_relationships_team_id_eq",
      function () {
        let team_id = $(this).val();
        if (team_id.length > 0) {
          $.ajax({
            url: `/filter_users_dropdowns`,
            method: "GET",
            dataType: "json",
            data: { team_id: team_id },
            error: function (xhr, status, error) {
              alert(`AJAX Error: ${status} ${error}`);
            },
            success: function (response) {
              setOrganisation(response);
              getAndFilterTeams(response);
            },
          });
        }
      },
    );
  }
});

function setOrganisation(response) {
  let organisation = response["organisation"];
  let organisation_dropdown = $("#q_organisation_id_eq")[0].selectize;
  organisation_dropdown.setValue(organisation[0], true);
}

function getAndFilterTeams(response) {
  let teams = response["teams"];
  let teams_dropdown = $("#q_role_user_relationships_team_id_eq")[0].selectize;
  teams_dropdown.clearOptions();
  $(teams).each(function () {
    teams_dropdown.addOption({ value: this[0], text: this[1] });
  });
}

window.deselectAll = function () {
  // this just cleans up the selected ids when they click clear/enter search
  selected_ids = [];
};

window.showEmailHistory = function (user_id, organisation_id) {
  event.preventDefault();
  $(".modal-card-body").empty();

  $.ajax({
    url:
      "users/modal_email_history?organisation_id=" +
      organisation_id +
      "&user_id=" +
      user_id,
    method: "POST",
    dataType: "json",
    data: { user_id, organisation_id },
    async: true,
    success: function (response) {
      $("#email_history_modal").addClass("is-active");
      // Name and description
      $(".modal-card-title").text(`Email History for ${response["data"][0]}`);
      //Check if any revision notes exist before looping
      if (response["data"][1].length > 0) {
        response["data"][1].forEach((email, index) => {
          $("#email_history_modal_notes").prepend(`
            <div class="card my-3">
              <header class="card-header">
                <p class="card-header-title has-text-weight-bold">${email.subject}</p>
                <p class="help is-right has-padding-10">Created at: ${email.created_at}</p>
              </header>
              <div class="card-content">
                <div class="content">
                  <p>${email.body}</p>
                </div>
              </div>
            </div>
          `);
        });
      } else {
        $("#email_history_modal_notes").prepend(`
          <div class="card my-3">
            <div class="card-content">
              <div class="content">
                <p>No email history for this user</p>
              </div>
            </div>
          </div>
        `);
      }
    },
  });
};
