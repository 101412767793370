let starting_assessments = [];
let assessments = [];
let deselected_assessments = [];
let selected = [];

$(document).on('turbolinks:load', function(){
  if ($('.edit_question').length > 0) {
    getStartingAssessments();
  }
});

function getStartingAssessments() {
  $('.assessment_checkbox').each(function(){
    if ($(this).prop('checked') == true) {
      starting_assessments.push(this.id);
    }
  })
}

window.getAssessmentsSelected = function() {
  $('.assessment_checkbox:checked').each(function(){
    assessments.push(this.id);
  })
  $('#selected_assessment_ids').val(assessments);
  $('.assessment_checkbox:not(:checked)').each(function(){
    if (starting_assessments.includes(this.id)) {
      deselected_assessments.push(this.id);
    }
  })
  $('#deselected_assessment_ids').val(deselected_assessments);
}

window.selectAllAssessments = function(select_all) {
  if ($(select_all).prop('checked') == true) {
    $('.assessment_checkbox').each(function(){
      $(this).prop('checked', true);
    })
  } else if ($(select_all).prop('checked') == false) {
    $('.assessment_checkbox').each(function(){
      $(this).prop('checked', false);
    })
  }
}

window.getDuplicatesSelected = function() {
  $('.duplicate_checkbox:checked').each(function(){
    selected.push(this.id);
  })
  $('#selected_dup_ids').val(selected);
}

window.selectAllDuplicates = function(select_all) {
  if ($(select_all).prop('checked') == true) {
    $('.duplicate_checkbox').each(function(){
      $(this).prop('checked', true);
    })
  } else if ($(select_all).prop('checked') == false) {
    $('.duplicate_checkbox').each(function(){
      $(this).prop('checked', false);
    })
  }
}