let starterJSON = [];
let compareArray = [];
let changesMade = false;

// getStartJson called on load and on AJAX success (as starting statuses will have to update to the present changes)
$(document).on("turbolinks:load", function () {
  if ($("#content_overview_table").length > 0) {
    if ($("#non_assignable_content_path").length > 0) {
      markRedContent();
    }
    getContent();
    $(".pagination-link").on("click", function (event) {
      checkForChanges();
    });
    $(".pagination-previous").on("click", function (event) {
      checkForChanges();
    });
    $(".pagination-next").on("click", function (event) {
      checkForChanges();
    });
    $(".button.filter").on("click", function (event) {
      checkForChanges();
    });
  }
});

window.checkForChanges = function () {
  if (changesMade === true) {
    if (confirm("Any unsaved changes will be discarded") == false) {
      window.event.preventDefault();
      window.event.stopImmediatePropagation();
    } else {
      $(".loading-overlay").show();
    }
  } else {
    $(".loading-overlay").show();
  }
};

window.windowGetContent = function () {
  getContent();
};

window.showOverlay = function () {
  $(".loading-overlay").show();
};

/* function to populate checkboxes and assign them correct information (id's).
   pass through nothing (default=false) to get all contents_membership from controller or
   pass in json of checkbox data to return id's for those checkboxes. */

function getContent(different = false) {
  if (different !== false) {
    new_records = [];
    for (let index in different) {
      json_item = different[index];
      for (let sub_key in json_item) {
        if (json_item[sub_key]) {
          content = sub_key.split("_");
          content_capitalized =
            content[0].charAt(0).toUpperCase() + content[0].slice(1);
          content_id = content[1];
          if (
            $("#manage_updates_path").val().includes("team_specific") ||
            $("#manage_updates_path").val().includes("user_specific")
          ) {
            object_id = content[4];
          } else {
            object_id = content[5];
          }
          if (json_item[sub_key]) {
            new_addition = [];
            if ($("#manage_updates_path").val().includes("user_specific")) {
              new_addition.push($("input#" + sub_key).attr("data_id"));
              new_addition.push(object_id);
            } else {
              new_addition.push(content_capitalized);
              new_addition.push(content_id);
              new_addition.push(object_id);
            }
            new_addition.push(content_capitalized);
            new_addition.push(content_id);
            new_addition.push(object_id);
            new_records.push(new_addition);
          }
        }
      }
    }
    // new records is either populated at this point with new_additions or needs to be emptied if only doing deletes
    different = new_records;
  }
  // if there has been changes or we're populating the page on load
  // n.b. in JS empty arrays - [] evaluate to false
  if (
    (!Array.isArray(different) && different == false) ||
    different.length > 0 ||
    $("#manage_updates_path").val().includes("assessment")
  ) {
    $.ajax({
      url: $("#contents_path").val(),
      method: "POST",
      dataType: "json",
      data: { different },
      async: true,
      success: function (response) {
        let content_response = response["content"];
        // users content overview was running really slow, this was due to us managing the looping through the contoller (bad)
        // ive refactored the code to no longer loop there, instead loop via javascript which is significantly faster.
        // this code is for base>users_content_overview_controller.rb only as the data we send is different to the rest.
        if ($("#manage_updates_path").val().includes("user_guide_specific")) {
          // get what content is blocked to the users sent through
          let users_blocked_content = content_response.splice(
            content_response.length - 1,
          );
          // get all user_ids sent through
          let user_ids = content_response.splice(content_response.length - 1);
          user_content = [];
          // loop through each user_id and duplicate the content sent through
          user_ids[0].forEach((id, i) => {
            // clone array and attach user id
            i = structuredClone(content_response);
            i.forEach((ct) => {
              ct[0] = ct[0] + id;
              ct[1] = ct[1] + id;
              // check to see if this user is blocked from this procedure
              if (
                searchForArray(users_blocked_content[0], [
                  id,
                  parseInt(ct[0].split("_")[0]),
                ]) === -1
              ) {
                ct[2] = "true";
              }
            });
            user_content.push(i);
          });
        }
        if ($("#manage_updates_path").val().includes("user_guide_specific")) {
          $(user_content).each(function () {
            initialLoadCheckProcedures(this, different);
          });
        } else {
          initialLoadCheckProcedures(content_response, different);
        }
        // once checked, find the parent/grandparent category/section and use js logic to determine checkbox status
        // for every procedure now ticked, check or indeterminate parent/grandparent based on child/sibling status
        $(":checkbox[id^=procedure]:checked").each(function () {
          let parent_class = $("input#" + this.className).attr("class");
          let max_parent = $("input." + this.className).length;
          let checked_counter = sibling_check_count(this);
          // clear out parent checkbox = 0, indeterminate = not max, checked = max
          if (checked_counter == 0) {
            uncheck_checkbox($("input#" + this.className));
            // check other categories - to discern what the section should be
            category_association = false;
            $("input." + parent_class).each(function () {
              if (
                $(this).prop("checked") == true ||
                $(this).prop("indeterminate") == true
              ) {
                category_association = true;
              }
            });
            if (category_association) {
              make_indeterminate($("input#" + parent_class));
            } else {
              uncheck_checkbox($("input#" + parent_class));
            }
          } else if (checked_counter > 0 && checked_counter < max_parent) {
            if ($("input#" + parent_class).prop("checked") == true) {
              make_indeterminate($("input#" + parent_class));
            }
            make_indeterminate($("input#" + this.className));
            // If no section association, make partial association
            if (
              $("input#" + parent_class).prop("checked") == false &&
              $("input#" + parent_class).prop("indeterminate") == false
            ) {
              $("input#" + parent_class).prop("indeterminate", true);
            }
          } else {
            check_checkbox($("input#" + this.className));
            // all other categories checked? make full association with section
            all_categories_checked = true;
            $("input." + parent_class).each(function () {
              if ($(this).prop("checked") == false) {
                all_categories_checked = false;
              }
            });
            if (all_categories_checked) {
              check_checkbox($("input#" + parent_class));
            } else {
              // I'm the only child procedure but my parent has siblings
              make_indeterminate($("input#" + parent_class));
            }
          }
        });
        $(":checkbox[id^=assessment]:checked").each(function () {
          let parent_class = $("input#" + this.className).attr("class");
          let max_parent = $("input." + this.className).length;
          let checked_counter = sibling_check_count(this);
          // clear out parent checkbox = 0, indeterminate = not max, checked = max
          if (checked_counter == 0) {
            uncheck_checkbox($("input#" + this.className));
            // check other categories - to discern what the section should be
            category_association = false;
            $("input." + parent_class).each(function () {
              if (
                $(this).prop("checked") == true ||
                $(this).prop("indeterminate") == true
              ) {
                category_association = true;
              }
            });
            if (category_association) {
              make_indeterminate($("input#" + parent_class));
            } else {
              uncheck_checkbox($("input#" + parent_class));
            }
          } else if (checked_counter > 0 && checked_counter < max_parent) {
            if ($("input#" + parent_class).prop("checked") == true) {
              make_indeterminate($("input#" + parent_class));
            }
            make_indeterminate($("input#" + this.className));
            // If no section association, make partial association
            if (
              $("input#" + parent_class).prop("checked") == false &&
              $("input#" + parent_class).prop("indeterminate") == false
            ) {
              $("input#" + parent_class).prop("indeterminate", true);
            }
          } else {
            check_checkbox($("input#" + this.className));
            // all other categories checked? make full association with section
            all_categories_checked = true;
            $("input." + parent_class).each(function () {
              if ($(this).prop("checked") == false) {
                all_categories_checked = false;
              }
            });
            if (all_categories_checked) {
              check_checkbox($("input#" + parent_class));
            } else {
              // I'm the only child procedure but my parent has siblings
              make_indeterminate($("input#" + parent_class));
            }
          }
        });
      },
      complete: function (response) {
        // these have all been moved to complete as all the checkbox statuses must be loaded in beforehand
        $(".loading-overlay").hide();
        changesMade = false;
        updateAssignAllStatus();
        getStartJson();
      },
    });
  }
}

function initialLoadCheckProcedures(content, different) {
  $(content).each(function () {
    let checkbox = $("input#" + this[1]);
    if ($(checkbox).length > 0) {
      checkbox.attr("data_id", this[0]);
      if (
        different == false &&
        (!$("#manage_updates_path").val().includes("user_specific") ||
          ($("#manage_updates_path").val().includes("user_specific") &&
            this[2] == "true")) &&
        !$("#manage_updates_path").val().includes("assessment")
      ) {
        // we only want to check procedure checkboxes
        // so we need to find out if it is a procedure
        if (
          checkbox.attr("id") !== undefined &&
          checkbox.attr("id").substring(0, 9) == "procedure"
        ) {
          checkbox.prop("checked", true);
        }
      }
      if ($("#manage_updates_path").val().includes("assessment")) {
        // assessments are tured on by default, turn off what comes back as data.
        // there are multiple checkboxes with the same id, turn off multiple
        $("input#" + checkbox.attr("id")).each(function () {
          $(this).prop("checked", false);
          // once all checkboxes have been turned off, check to see if all checkboxes for parent have been turned off
          number_of_assessments = 0;
          number_of_assessments_turned_off = 0;
          $("input." + $(this).attr("class")).each(function () {
            number_of_assessments = number_of_assessments + 1;
            if ($(this).prop("checked") == false) {
              number_of_assessments_turned_off =
                number_of_assessments_turned_off + 1;
            }
          });
          if (number_of_assessments_turned_off == number_of_assessments) {
            $("input#" + $(this).attr("class")).prop("checked", false);
            $("input#" + $(this).attr("class")).prop("indeterminate", false);
          }
        });
      }
    }
  });
}

function searchForArray(haystack, needle) {
  var i, j, current;
  for (i = 0; i < haystack.length; ++i) {
    if (needle.length === haystack[i].length) {
      current = haystack[i];
      for (j = 0; j < needle.length && needle[j] === current[j]; ++j);
      if (j === needle.length) return i;
    }
  }
  return -1;
}

function updateAssignAllStatus() {
  $('input[id^="all"]').each(function () {
    let sub = this.id.split("_");
    all_checked = true;
    $('input[id^="' + sub[1] + "_" + sub[2] + '_"]').each(function () {
      if (!$(this).prop("checked") || $(this).prop("indeterminate")) {
        all_checked = false;
      }
    });
    if (all_checked) {
      $(this).prop("checked", true);
    } else {
      $(this).prop("checked", false);
    }
  });
}

// gets all the starting statuses of the checkboxes to be used as a comparator for changes
function getStartJson() {
  if ($("#content_overview_table").length > 0) {
    starterJSON = [];
    $(":checkbox:not([id^=all]):not([class=compare_object])").each(function () {
      if ($("#manage_updates_path").val().includes("assessment")) {
        if (this.id.includes("assessment")) {
          if (
            $(this).prop("checked") == true ||
            $(this).prop("indeterminate") == true
          ) {
            item = {};
            item[this.id] = true;
            starterJSON.push(item);
          } else {
            item = {};
            item[this.id] = false;
            starterJSON.push(item);
          }
        }
      } else {
        if (
          $(this).prop("checked") == true ||
          $(this).prop("indeterminate") == true
        ) {
          item = {};
          item[this.id] = true;
          starterJSON.push(item);
        } else {
          item = {};
          item[this.id] = false;
          starterJSON.push(item);
        }
      }
    });
  }
}

// Checkbox logic functions
function check_checkbox(checkbox) {
  checkbox.prop("indeterminate", false);
  checkbox.prop("checked", true);
}

function uncheck_checkbox(checkbox) {
  checkbox.prop("indeterminate", false);
  checkbox.prop("checked", false);
}

function make_indeterminate(checkbox) {
  checkbox.prop("checked", false);
  checkbox.prop("indeterminate", true);
}

function check_children(checkbox) {
  $("input." + checkbox.id).each(function () {
    if ($("#manage_updates_path").val().includes("assessment")) {
      $("input#" + this.id).each(function () {
        if ($(this).prop("checked") != true) {
          $(this).prop("indeterminate", false);
          $(this).prop("checked", true);
          check_procedure(this);
        }
      });
    } else {
      if ($(this).prop("checked") != true) {
        $(this).prop("indeterminate", false);
        $(this).prop("checked", true);
      }
    }
  });
}

function uncheck_children(checkbox) {
  $("input." + checkbox.id).each(function () {
    if ($("#manage_updates_path").val().includes("assessment")) {
      $("input#" + this.id).each(function () {
        if ($(this).prop("checked") == true) {
          $(this).prop("checked", false);
          check_procedure(this);
        }
      });
    } else {
      if ($(this).prop("checked") == true) {
        $(this).prop("checked", false);
      }
    }
  });
}

function sibling_check_count(checkbox) {
  let sibling_count = 0;
  $("input." + checkbox.className).each(function () {
    if ($(this).prop("checked") == true) {
      sibling_count++;
    }
  });
  return sibling_count;
}

// called when checking a general checkbox in the content overview table
// for input checkboxes -> parent id = child class
window.contentOverviewCheckChildren = function (checkbox) {
  if (changesMade === false) {
    changesMade = true;
  }
  // SECTION
  if (checkbox.id.toString().startsWith("section")) {
    check_section(checkbox);
  }

  // CATEGORY
  if (checkbox.id.toString().startsWith("category")) {
    check_category(checkbox);
  }

  // PROCEDURE
  if (checkbox.id.toString().startsWith("procedure")) {
    check_procedure(checkbox);
  }

  // SECTION
  if (checkbox.id.toString().startsWith("leftoverassessments")) {
    check_section(checkbox);
  }

  // ASSESSMENT
  if (checkbox.id.toString().startsWith("assessment")) {
    if (checkbox.checked) {
      $("input#" + checkbox.id).each(function () {
        $(this).prop("checked", true);
        check_procedure(this);
      });
    } else {
      $("input#" + checkbox.id).each(function () {
        $(this).prop("checked", false);
        check_procedure(this);
      });
    }
    check_procedure(checkbox);
  }

  if (checkbox.id.toString().startsWith("all")) {
    let sub = checkbox.id.split("_");
    if (sub[1] == "assessment") {
      if (checkbox.checked) {
        $("input#" + checkbox.id).each(function () {
          $(this).prop("checked", true);
        });
      } else {
        $("input#" + checkbox.id).each(function () {
          $(this).prop("checked", false);
        });
      }
    }
    if ($("input#" + checkbox.id).prop("checked") != true) {
      $('input[id^="' + sub[1] + "_" + sub[2] + '_"]').each(function () {
        // Change status of all checkboxes
        if ($(this).prop("indeterminate")) {
          $(this).prop("indeterminate", false);
        } else {
          $(this).prop("checked", false);
        }

        // Run checkbox logic
        if (this.id.toString().startsWith("section")) {
          check_section(this);
        }

        if (this.id.toString().startsWith("category")) {
          check_category(this);
        }

        if (this.id.toString().startsWith("procedure")) {
          check_procedure(this);
        }

        if (this.id.toString().startsWith("assessment")) {
          check_procedure(this);
        }

        //LOCAL ASSESSMENT
        if (this.id.toString().startsWith("leftoverassessments")) {
          check_section(this);
        }
      });
    } else {
      $('input[id^="' + sub[1] + "_" + sub[2] + '_"]').each(function () {
        // Change status of all checkboxes
        if ($(this).prop("indeterminate")) {
          check_checkbox($(this));
        } else {
          $(this).prop("checked", true);
        }

        // Run checkbox logic
        if (this.id.toString().startsWith("section")) {
          check_section(this);
        }

        if (this.id.toString().startsWith("category")) {
          check_category(this);
        }

        if (this.id.toString().startsWith("procedure")) {
          check_procedure(this);
        }

        if (this.id.toString().startsWith("assessment")) {
          check_procedure(this);
        }

        //LOCAL ASSESSMENT
        if (this.id.toString().startsWith("leftoverassessments")) {
          check_section(this);
        }
      });
    }
  }
  updateAssignAllStatus();
};

function check_section(checkbox) {
  // CHECK
  check_children(checkbox);
  $("input." + checkbox.id).each(function () {
    check_children(this);
  });
  // UNCHECK
  if ($("input#" + checkbox.id).prop("checked") != true) {
    uncheck_children(checkbox);
    $("input." + checkbox.id).each(function () {
      uncheck_children(this);
    });
  }
}

function check_category(checkbox) {
  let max_parent = $("input." + checkbox.className).length;
  let checked_counter = sibling_check_count(checkbox);
  // CHECK
  check_children(checkbox);
  // check if other section.categories checked - all checked = section checked, else indeterminate
  if (checked_counter != max_parent) {
    $("input#" + checkbox.className).prop("indeterminate", true);
  } else {
    check_checkbox($("input#" + checkbox.className));
  }
  // CATEGORY UNCHECK
  if ($("input#" + checkbox.id).prop("checked") != true) {
    uncheck_children(checkbox);
    // if parent checked - make partial (unless only 1 category in section)
    // else if parent partial and only self checked, make parent unchecked too
    if ($("input#" + checkbox.className).prop("checked") == true) {
      if (max_parent == 1) {
        uncheck_checkbox($("input#" + checkbox.className));
      } else {
        make_indeterminate($("input#" + checkbox.className));
      }
    } else {
      let sibling_associated = false;
      $("input." + checkbox.className).each(function () {
        if (
          $(this).prop("checked") == true ||
          $(this).prop("indeterminate") == true
        ) {
          sibling_associated = true;
        }
      });
      if (!sibling_associated) {
        $("input#" + checkbox.className).prop("indeterminate", false);
      }
    }
  }
}

function check_procedure(checkbox) {
  let parent_class = $("input#" + checkbox.className).attr("class");
  let max_parent = $("input." + checkbox.className).length;
  let checked_counter = sibling_check_count(checkbox);
  // clear out parent checkbox = 0, indeterminate = not max, checked = max
  if (checked_counter == 0) {
    uncheck_checkbox($("input#" + checkbox.className));
    // check other categories - to discern what the section should be
    category_association = false;
    $("input." + parent_class).each(function () {
      if (
        $(this).prop("checked") == true ||
        $(this).prop("indeterminate") == true
      ) {
        category_association = true;
      }
    });
    if (category_association) {
      make_indeterminate($("input#" + parent_class));
    } else {
      uncheck_checkbox($("input#" + parent_class));
    }
  } else if (checked_counter > 0 && checked_counter < max_parent) {
    if ($("input#" + parent_class).prop("checked") == true) {
      make_indeterminate($("input#" + parent_class));
    }
    make_indeterminate($("input#" + checkbox.className));
    // If no section association, make partial association
    if (
      $("input#" + parent_class).prop("checked") == false &&
      $("input#" + parent_class).prop("indeterminate") == false
    ) {
      $("input#" + parent_class).prop("indeterminate", true);
    }
  } else {
    check_checkbox($("input#" + checkbox.className));
    // all other categories checked? make full association with section
    all_categories_checked = true;
    $("input." + parent_class).each(function () {
      if ($(this).prop("checked") == false) {
        all_categories_checked = false;
      }
    });
    if (all_categories_checked) {
      check_checkbox($("input#" + parent_class));
    } else {
      make_indeterminate($("input#" + parent_class));
    }
  }
}

// called when a user clicks a section or a category in the first column of contents overview table
window.contentOverviewHideChildren = function (p_contents) {
  // toggle child row
  $("tr." + p_contents.className).toggle();
  // change chevron
  let icon_class = p_contents.children[0].children[0];
  if ($(icon_class).hasClass("fas fa-chevron-right")) {
    $(icon_class).removeClass("fa-chevron-right").addClass("fa-chevron-down");
  } else {
    $(icon_class).removeClass("fa-chevron-down").addClass("fa-chevron-right");
  }
  // logic for blanket close if section is closed with categories and procedures open
  if (p_contents.className.toString().startsWith("section")) {
    // hide the procedures - they should be hidden whenever clicking a section anyway
    $("td." + p_contents.className)
      .parent()
      .hide();
    // reset category chevrons to right
    $("tr." + p_contents.className).each(function () {
      let category_icon_class =
        this.children[0].children[0].children[0].children[0];
      if ($(category_icon_class).hasClass("fas fa-chevron-down")) {
        $(category_icon_class)
          .removeClass("fa-chevron-down")
          .addClass("fa-chevron-right");
      }
    });
  }
};

window.restore = function () {
  $("#specific_object_ids").attr("value", []);
};

window.compare = function (checkbox) {
  if ($(checkbox).prop("checked")) {
    compareArray.push(checkbox.id);
  } else {
    const index = compareArray.indexOf(checkbox.id);
    if (index > -1) {
      compareArray.splice(index, 1);
    }
  }
  $("#specific_object_ids").attr("value", compareArray);
  const objCount = $("#specific_object_ids").val().split(",").length;

  if (objCount > 1) {
    $("#compare-button").attr("disabled", false);
  } else {
    $("#compare-button").attr("disabled", true);
  }
};

window.makeHeaderSticky = function () {
  if ($("[data-action=collapse]").hasClass("is-active")) {
    $("#revision_note_accordion_header").css({ position: "" });
  } else {
    $("#revision_note_accordion_header").css({
      position: "fixed",
      width: "650px",
      "z-index": "2",
    });
  }
};

window.revisionNotesModal = function (object) {
  // Clear modal before populating
  $(".modal-card-body").empty();

  $.ajax({
    url: $("#revision_notes_path").val(),
    method: "POST",
    dataType: "json",
    data: { object },
    async: true,
    success: function (response) {
      // Display modal
      $("#revision_notes_modal_notes").parent().parent().addClass("is-active");
      // Name and description
      $(".modal-card-title").text(
        `Revision Notes & Description for ${response["data"][0]}`,
      );
      $(".revision_notes_modal_description").append(
        `<p style="margin-left: 5px;">${response["data"][1]}</p>`,
      );
      //Check if any revision notes exist before looping
      if (response["data"][2].length > 0) {
        response["data"][2].forEach((note, index) => {
          $("#revision_notes_modal_notes").prepend(`
            <div class="card my-3">
              <header class="card-header">
                <p class="card-header-title has-text-weight-bold">Revision Note ${index + 1}</p>
                <p class="help is-right has-padding-10">Updated at: ${note.created_at} by ${note.user}</p>
              </header>
              <div class="card-content">
                <div class="content">
                  <p>${note.description}</p>
                </div>
              </div>
            </div>
          `);
        });
      } else {
        $("#revision_notes_modal_notes").prepend(`
          <div class="card my-3">
            <div class="card-content">
              <div class="content">
                <p>No revision notes have been created</p>
              </div>
            </div>
          </div>
        `);
      }
    },
  });
};

/* Submit changes logic - grabs all checkbox statuses for every checkbox on the page
   and puts them in changesJSON to compare them with the starterJSON and look for changes */
window.submitThroughAjax = function () {
  changesMade = false;
  if ($("#revision_note_description_input").val().length > 0) {
    if (
      confirm(
        "Please be aware, If content is being removed this may affect local content.",
      ) == true
    ) {
      $("#revision_note_accordion").show();
      //handle new revision note in singular view - needs to move into accordion
      // Only add note to accordion on singlular or duplicate views
      if ($("#type_of_view").val() == "true") {
        let revision_note_description = $(
          "#revision_note_description_input",
        ).val();
        let revision_note_index = $(".revision_notes_card").size() + 1;
        $("#revision_notes_cards").prepend(
          `<div class="card my-3">
              <header class="card-header">
                <p class="card-header-title">Revision Note ${revision_note_index}</p>
                <p class="help is-right has-padding-10">Updated just now</p>
              </header>
              <div class="card-content">
                <div class="content">
                  <p>${revision_note_description}</p>
                </div>
            </div>
          </div>`,
        );
      }
      $(".loading-overlay").show();
      changesJson = [];
      $(
        ":checkbox:not([id^=all]):not([class=compare_object]):not([class=is-checkradio])",
      ).each(function () {
        if ($("#manage_updates_path").val().includes("assessment")) {
          if (this.id.includes("assessment")) {
            if (
              $(this).prop("checked") == true ||
              $(this).prop("indeterminate") == true
            ) {
              item = {};
              item[this.id] = true;
              changesJson.push(item);
            } else {
              item = {};
              item[this.id] = false;
              changesJson.push(item);
            }
          }
        } else {
          if (
            $(this).prop("checked") == true ||
            $(this).prop("indeterminate") == true
          ) {
            item = {};
            item[this.id] = true;
            changesJson.push(item);
          } else {
            item = {};
            item[this.id] = false;
            changesJson.push(item);
          }
        }
      });
      let different = diff(starterJSON, changesJson);
      final_output = prep(different);
      if ($("#email_organisation_users_with_new_content").length > 0) {
        final_output.push([
          $("#email_organisation_users_with_new_content").prop("checked"),
        ]);
        $("#email_organisation_users_with_new_content").prop("checked", false);
        if ($("#associate_to_all_teams_users").length > 0) {
          $("#email_organisation_users_with_new_content").prop(
            "disabled",
            true,
          );
        }
      }
      if ($("#associate_to_all_teams_users").length > 0) {
        // set checkbox to false and page isnt reloaded
        $("#associate_to_all_teams_users").prop("checked", false);
      }
      var requestParams = [];
      $.each(spliceIntoChunks(final_output[0], 10), function (key, value) {
        let add_data = [value, [], [], []];
        if (final_output[4]) {
          add_data.push(final_output[4]);
        }
        sendData(add_data);
      });
      $.each(spliceIntoChunks(final_output[1], 10), function (key, value) {
        let remove_data = [[], value, [], []];
        // assessments work the opposite way round so we need to remove
        if (
          final_output[4] &&
          $("#manage_updates_path").val().includes("assessment")
        ) {
          remove_data.push(final_output[4]);
        }
        sendData(remove_data);
      });
      let revision_note_data = [
        [],
        [],
        final_output[2],
        $.unique(final_output[3].sort()),
      ];
      sendDataLast(revision_note_data, different);
      $("#new_revision_note").hide();
      $("#show_revision_note_button").show();
    }
  } else {
    alert("Description cannot be blank.");
  }
};

function sendData(final_output) {
  $.ajax({
    url: $("#manage_updates_path").val(),
    method: "POST",
    dataType: "json",
    data: { final_output },
    async: true,
  });
}

function sendDataLast(final_output, different) {
  $.ajax({
    url: $("#manage_updates_path").val(),
    method: "POST",
    dataType: "json",
    data: { final_output },
    async: true,
    success: function (response) {
      window.scrollTo(0, 0);
      if (response["status"]) {
        $("#changes-alert").show();
      }
      starterJSON = changesJson;
    },
    complete: function (response) {
      $(".loading-overlay").hide();
    },
  });
}

function spliceIntoChunks(arr, chunkSize) {
  const res = [];
  while (arr.length > 0) {
    const chunk = arr.splice(0, chunkSize);
    res.push(chunk);
  }
  return res;
}

// function to find differences in JSON
function diff(obj1, obj2) {
  result = {};
  for (let i in obj1) {
    let val1 = obj1[i];
    let val2 = obj2[i];
    let left;
    let right;
    for (let j in val1) {
      left = val1[j];
    }
    for (let k in val2) {
      right = val2[k];
    }
    if (left !== right) {
      result[i] = obj2[i];
    }
  }
  return result;
}

// function to prep the changes for database execution on the controller
function prep(json_input) {
  if ($("#manage_updates_path").val().includes("team_specific")) {
    object_type = "team_id";
  } else if ($("#manage_updates_path").val().includes("user_specific")) {
    object_type = "user_id";
  } else {
    object_type = "membership_template_id";
  }
  create_json = [];
  delete_json = [];
  revision_note_json = [];
  revision_note_description = [];
  revision_note_description.push($("#revision_note_description_input").val());
  $("#revision_note_description_input").val("");
  for (let index in json_input) {
    json_item = json_input[index];
    for (let sub_key in json_item) {
      content = sub_key.split("_");
      content_capitalized =
        content[0].charAt(0).toUpperCase() + content[0].slice(1);
      content_id = content[1];
      if (
        $("#manage_updates_path").val().includes("team_specific") ||
        $("#manage_updates_path").val().includes("user_specific")
      ) {
        object_id = content[4];
      } else {
        object_id = content[5];
      }
      if (json_item[sub_key]) {
        if ($("#manage_updates_path").val().includes("assessment")) {
          // standard assessment logic works the opposite, so delete instead of add.
          remove_addition = {};
          remove_addition["assessment_id"] = content_id;
          remove_addition[object_type] = object_id;
          if ($("#manage_updates_path").val().includes("team_specific")) {
            remove_addition["block_content_from_users"] = $(
              "#associate_to_all_teams_users",
            ).prop("checked");
          }
          delete_json.push(remove_addition);

          revision_note_object_id = object_id;
          revision_note_json.push(revision_note_object_id);
        } else {
          new_addition = {};
          if ($("#manage_updates_path").val().includes("user_specific")) {
            if ($("input#" + sub_key).attr("data_id") !== undefined) {
              new_addition["contents_team_id"] = $("input#" + sub_key)
                .attr("data_id")
                .split("_")[0];
            }
            // for users assessments only
            if ($("#team_id").length > 0) {
              new_addition["team_id"] = $("#team_id").val();
            }
            new_addition[object_type] = object_id;
          } else {
            if ($("#manage_updates_path").val().includes("team_specific")) {
              new_addition["block_content_from_users"] = $(
                "#associate_to_all_teams_users",
              ).prop("checked");
            }
            new_addition["content_type"] = content_capitalized;
            new_addition["content_id"] = content_id;
            new_addition[object_type] = object_id;
          }
          create_json.push(new_addition);

          revision_note_object_id = object_id;
          revision_note_json.push(revision_note_object_id);
        }
      } else {
        if ($("#manage_updates_path").val().includes("assessment")) {
          // standard assessment logic works the opposite, so add instead of delete.
          new_addition = {};
          new_addition["assessment_id"] = content_id;
          new_addition[object_type] = object_id;
          // for users assessments only
          if ($("#team_id").length > 0) {
            new_addition["team_id"] = $("#team_id").val();
          }
          create_json.push(new_addition);

          revision_note_object_id = object_id;
          revision_note_json.push(revision_note_object_id);
        } else {
          remove_addition = {};
          if ($("#team_id").length > 0) {
            remove_addition["team_id"] = $("#team_id").val();
          }
          remove_addition["content_type"] = content_capitalized;
          remove_addition["content_id"] = content_id;
          remove_addition[object_type] = object_id;
          delete_json.push(remove_addition);

          revision_note_object_id = object_id;
          revision_note_json.push(revision_note_object_id);
        }
      }
    }
  }
  return [
    create_json,
    delete_json,
    revision_note_description,
    revision_note_json,
  ];
}

window.showRevisionNote = function () {
  $("#new_revision_note").show();
  $("#show_revision_note_button").hide();
};

window.hideRevisionNote = function () {
  $("#new_revision_note").hide();
  $("#show_revision_note_button").show();
};

window.enableCheckbox = function (checkbox) {
  if ($(checkbox).prop("checked") == true) {
    $("#email_organisation_users_with_new_content").prop("disabled", false);
  } else {
    $("#email_organisation_users_with_new_content").prop("disabled", true);
    $("#email_organisation_users_with_new_content").prop("checked", false);
  }
};

function markRedContent() {
  $.ajax({
    url: $("#non_assignable_content_path").val(),
    method: "POST",
    dataType: "json",
    data: {},
    async: true,
    success: function (response) {
      if (response["type"] == "team") {
        for (let index in response["status"]) {
          for (let assessment in response["status"][index]) {
            $(
              "#assessment_" +
                response["status"][index][assessment] +
                "_team_id_" +
                index,
            )
              .closest("td")
              .css("background-color", "#d61515");
          }
        }
      } else if (response["type"] == "user") {
        for (let index in response["status"]) {
          for (let assessment in response["status"][index]) {
            $(
              "#assessment_" +
                response["status"][index][assessment] +
                "_user_id_" +
                index,
            )
              .closest("td")
              .css("background-color", "#d61515");
          }
        }
      }
    },
  });
}
