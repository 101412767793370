window.uncheckSibling = function(checkbox) {
  if (checkbox.checked) {
    if ($(checkbox).attr("id") == "user[contributor]") {
      // for whatever reason doing $("#user[csl_admin]").prop('checked', false) doesnt work so have use use getElement
      $(document.getElementById("user[csl_admin]")).prop('checked', false)
    } else if ($(checkbox).attr("id") == "user[csl_admin]") {
      $(document.getElementById("user[contributor]")).prop('checked', false)
    }
  }
}
