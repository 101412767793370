import tippy from "tippy.js";

window.unavailableTippy = function (question_id) {
  tippy("#question_" + question_id, {
    content:
      "This question was made available in Question Bank Ref " +
      $("#available_question_id_" + question_id).attr("data-id"),
  });
};

window.compulsoryTippy = function (question_id) {
  tippy("#question_" + question_id, {
    content:
      "This question was made compulsory in Question Bank Ref " +
      $("#compulsory_question_id_" + question_id).attr("data-id"),
  });
};

window.selectQuestionsHelp = function () {
  tippy("#select_questions_help", {
    content:
      '<a href="#" alt="addremove">How to Add / Remove Compulsory question</a><br><a href="#" alt="availableunavailable">How to Make questions available / unavailable for random selection</a><br><a href="#" alt="random">If questions made available for random selection, how to specify the number of random questions</a>',
    allowHTML: true,
    interactive: true,
    hideOnClick: true,
    theme: "light-border",
  });
};

window.checklistSubscribe = function () {
  tippy("#checklist-button", {
    content:
      "<p>Your organisation does not current subscribe to the competency checklist module</p>",
    allowHTML: true,
  });
};

window.assessmentTippy = function (id, message, record_type) {
  if (message) {
    tippy("#assessment_" + id + "_tippy", {
      content:
        "This local assessment uses question bank(s) from the following guides [" +
        message +
        "]",
      allowHTML: true,
    });
  } else {
    tippy("#assessment_" + id + "_tippy", {
      content:
        "This local assessment uses question bank(s) from local question banks.",
      allowHTML: true,
    });
  }
};

window.localAssessmentHelpTippy = function (record_type) {
  tippy("#local_assessment_help_tippy", {
    content:
      "Ensure that your " +
      record_type +
      " has access to the guides linked to the local assessments. To see which guides are associated with a specific assessment, click on the 'i' icon next to it.",
    allowHTML: true,
  });
};

window.teamTippy = function (id, sections, categories, procedures) {
  tippy("#team_" + id + "_tippy", {
    content:
      "This team has access to the following sections: - [" + sections + "]",
    allowHTML: true,
  });
};
