$(document).on('turbolinks:load', function() {
  showTimeAllowedInMinutes();
  showRetakes();
  showWithholdResults();

  if ($('#pass_percentage').length > 0) {
    if ($('#assessment_pass_mark_percentage').val().length > 1) {
      $('#correct_answers').append(Math.ceil(($('#assessment_pass_mark_percentage').val() / 100) * $('#total_questions').val()))
    }
    $(document).on("keyup", '#assessment_pass_mark_percentage', function() {
      let pass_mark = $(this).val();
      let total_questions = $('#total_questions').val();
      let number_of_correct_answers = (pass_mark / 100) * total_questions;
      if ($('#correct_answers').length > 0) {
        $('#correct_answers').remove();
      }
      $('#pass_percentage').append(`
        <p class="help" id="correct_answers">Number of correct answers needed to pass assessment: ${Math.ceil(number_of_correct_answers)}</p>`);
    })
  }
})

window.showTimeAllowedInMinutes = function() {
  if ($('#assessment_time_limited').prop('checked') == true) {
    $('#time_allowed').show();
  } else {
    $('#time_allowed').hide();
  }
}

window.showRetakes = function() {
  if ($('#assessment_retakes_allowed').prop('checked') == true) {
    $('#retakes').show();
  } else {
    $('#retakes').hide();
  }
}

window.showWithholdResults = function() {
  if ($('#assessment_withhold_results').length > 0) {
    if ($('#assessment_withhold_results').prop('checked') == true) {
      $('#withhold_results').show();
      saveChanges(true);
    } else {
      $('#withhold_results').hide();
      saveChanges(false);
    }
  }
}

function saveChanges(state) {
  $.ajax({
    url: `/content_management/update_withhold_results`,
    method: "GET",
    dataType: "json",
    data: {assessment_id: $('#assessment_id').val(), withhold_results: state},
  });
}
