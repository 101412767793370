$(document).on("turbolinks:load", function () {
  if ($("#transfer_management").length > 0) {
    $(document).on("change", "#organisation_id", function () {
      let organisation_id = $(this).val();
      if (organisation_id.length > 0) {
        $.ajax({
          url: `/filter_users_dropdowns`,
          method: "GET",
          dataType: "json",
          data: { organisation_id: organisation_id },
          error: function (xhr, status, error) {
            alert(`AJAX Error: ${status} ${error}`);
          },
          success: function (response) {
            //// These lines resets the child values to avoid incongruous behavior
            if ($("#team_id").val().length > 0) {
              $("#team_id")[0].selectize.setValue(null, true);
            }
            getAndFilterTeams(response);
          },
        });
      }
    });
  }
});

function setOrganisation(response) {
  let organisation = response["organisation"];
  if ($("#organisation_id")[0] != null) {
    let organisation_dropdown = $("#organisation_id")[0].selectize;
    organisation_dropdown.setValue(organisation[0], true);
  }
}

function getAndFilterTeams(response) {
  let teams = response["teams"];
  let teams_dropdown = $("#team_id")[0].selectize;
  teams_dropdown.clearOptions();
  $(teams).each(function () {
    teams_dropdown.addOption({ value: this[0], text: this[1] });
  });
  // set value to be first team available
  teams_dropdown.setValue(teams[0][0]);
}
