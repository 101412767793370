window.showTeam = function(team_dropdown) {
  if (team_dropdown.value != "Super User") {
    $("#team-dropdown").css("display","block");
    $.ajax({
      url: `/super_admin/organisations/` + $("#selected_organisation_id").val() + '/teams',
      method: "GET",
      dataType: "json",
      error: function (xhr, status, error) {
        alert(`AJAX Error: ${status} ${error}`);
      },
      success: function (response) {
          let teams = response["teams"];
          let teams_dropdown = $("#team")[0].selectize
          teams_dropdown.clearOptions();
          $(teams).each(function() {
            teams_dropdown.addOption({value: this[0], text: this[1]});
          });
      }
    });
  } else {
    $("#team-dropdown").css("display","none");
  }
}
