// This function alters the HTMl <a> tags for all ActionText divs with this
// ID and forces any links to always open in a new tab
$(document).on('turbolinks:load', function() {
  if ($('#action-text-content').length > 0) {
    $('#action-text-content a:not(.button)').each(function() {
      $('#action-text-content a').attr("target", "_blank");
    })
  }
  if ($('.trix-content').length > 0) {
    $('.trix-content a:not(.button)').each(function() {
      $('.trix-content a').attr("target", "_blank");
    })
  }
})