$(document).on('click', "#modal-activator-mfa", function(e) {
  e.preventDefault();

  const mfaModal = $("#mfa_modal");
  const mfaButton = $("#mfa_button");
  const userId = $('#user_id').val();
  const mfaQRCode = $('#mfa_qr_code');
  const mfaBackupCodes = $('#mfa_backup_codes');

  mfaModal.addClass("is-active");


  $.ajax({
    beforeSend: function() {
      $(".loading-overlay").show();
    },
    url: 'enable_mfa',
    method: "POST",
    data: { user_id: userId },
    error: function(xhr, status, error) {
      alert(`AJAX Error: ${status} ${error}`);
    },
    success: function(response) {
      mfaQRCode.html(response["qr_code"]);
      const backupCodesHtml = response["backup_codes"].map(function(code) {
        return `<pre>${code}</pre>`;
      }).join('');
      mfaBackupCodes.html(backupCodesHtml);
    },
    complete: function() {
      $(".loading-overlay").hide();
      mfaButton.html("<div class='notification is-info is-light'><p>Multi Factor authentication is enabled for this account. Please contact support to disable it.</p></div>");
    }
  });
});
