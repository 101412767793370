//populated on submit - checkbox statuses
let selectionArray = [];
let mt_relationship_lookup = [];
//populated on load - checkbox statuses
let mt_starting_assignments = [];
let cat_starting_assignments = [];

// called on submit - populates the hidden fields on the section/category/procedure edit pages
window.populateHidden = function() {
  // find every checkbox and record it's status - push it to selectionArray
  $(':checkbox[id^="mt_"]').each(function() {
    if (($(this).prop('checked') == true)) {
      item = {};
      item[$(this).attr('id').split("_")[1]] = 1
      selectionArray.push(item);
    } else if (($(this).prop('indeterminate') == true) && ($(this).prop('checked') == false)) {
      item = {};
      item[$(this).attr('id').split("_")[1]] = 2
      selectionArray.push(item);
    } else if (($(this).prop('indeterminate') == false) && ($(this).prop('checked') == false)) {
      item = {};
      item[$(this).attr('id').split("_")[1]] = 0
      selectionArray.push(item);
    }
  });
  // call the function diff between the starting assignments and the current selections - this will show what has changed
  checkbox_changes = diff(mt_starting_assignments, selectionArray);
  // anything in checkbox_changes with a value of 1 is a new creation, anything with a 0 is a deletion
  let additions = [];
  let deletions = [];
  for (let index in checkbox_changes) {
    change = checkbox_changes[index];
    for (let key in change) {
      if (change[key] == 1) {
        additions.push(key);
      } else {
        deletions.push(key);
      }
    }
  }
  // if recent additions/deletions already contains values e.g. after validation error, append the new changes on the end
  // else just set the value
  if ($('#recent_additions').val().length > 0) {
    $('#recent_additions').attr('value', $('#recent_additions').val() + "," + additions);
  } else {
    $('#recent_additions').attr('value', additions);
  }
  if ($('#recent_deletions').val().length > 0) {
    $('#recent_deletions').attr('value', $('#recent_deletions').val() + "," + deletions);
  } else {
    $('#recent_deletions').attr('value', deletions);
  }
}

window.getCollectionSelectValue = function(values) {
  // find the value of the collection select that the user has selected
  $('.table-loading-overlay').show();
  let selected_value_id = $(values).val();
  // add the selected value to the hidden field - section_dropdown so still accessible even after validation errors
  $('#section_dropdown').val(selected_value_id);
  $('#contents_membership_table').show();
  $('#table_helper_text').show();
  hideOtherColumns(selected_value_id);
  getStartingAssociations(`/super_admin/content_management/sections/starting_associations?section_id=${selected_value_id}`, false);
  if ($('#new_edit_procedure').length > 0) {
    populateCategoriesDropdown();
    $('#procedure_category_id_field').show();
  }
}

$(document).on('turbolinks:load', function(){
  validationRepopulation();
  postValidationInformation();
  if ($('#sections_content_membership_table').length > 0) {
    $(".loading-overlay").hide();
    getStartingAssociations(`/super_admin/content_management/sections/starting_associations`, true);
  } else if ($('#contents_membership_table').length > 0) {
    $(".loading-overlay").hide();
    $('.table-loading-overlay').hide();
    // we don't call getStartingAssociations in here like we do with the sections view because it's called in getCollectionSelectValue instead
    // a necessary step the user must do before the table is even shown.
  }

  if ($('.edit_category').length > 0) {
    categoryStartingAssociations();
  }
});

function validationRepopulation() {
  // if there are ids in addition and deletion hidden fields - repopulate those checkboxes on load
  // that way the user will not lose those selections through post validation errors on not only create but silly mistakes on edit too
  if ($('#sections_content_membership_table').length > 0 || $('#contents_membership_table').length > 0) {
    if ($('#recent_additions').val().length > 0) {
      checkbox_ids = $('#recent_additions').val().split(',');
      for (const x of checkbox_ids) {
        $('[id="mt_' + x + '"]').prop('checked', true);
        $('[id="mt_' + x + '"]').prop('indeterminate', false);
      }
    }
    if ($('#recent_deletions').val().length > 0) {
      checkbox_ids = $('#recent_deletions').val().split(',');
      for (const x of checkbox_ids) {
        $('[id="mt_' + x + '"]').prop('checked', false);
        $('[id="mt_' + x + '"]').prop('indeterminate', false);
      }
    }
  }
}

function populateCategoriesDropdown() {
  let section_id = $('#section_dropdown').val();
  if(section_id.length > 0) {
    $.ajax({
      url: `/super_admin/content_management/filter_question_banks_dropdowns`,
      method: "GET",
      dataType: "json",
      data: {section_id: section_id},
      error: function (xhr, status, error) {
        alert(`AJAX Error: ${status} ${error}`);
      },
      success: function (response) {
        //// These lines resets the child values to avoid incongruous behavior
        if ($('#procedure_category_id').val().length > 0) {
          $('#procedure_category_id')[0].selectize.setValue(null, true);
        };
        getAndFilterCategories(response);
      }
    });
  }
}

function getAndFilterCategories(response) {
  let categories = response["categories"];
  let category_dropdown = $("#procedure_category_id")[0].selectize
  category_dropdown.clearOptions();
  $(categories).each(function() {
    category_dropdown.addOption({value: this[0], text: this[1]});
  });
}

// use the hidden field value to show the table again along with the correct column - post validation error
function postValidationInformation() {
  if ($('#section_dropdown').length > 0) {
    if ($('#section_dropdown').val().length > 0) {
      selected_value_id = $('#section_dropdown').val();
      $('#contents_membership_table').show();
      $('#table_helper_text').show();
      hideOtherColumns(selected_value_id);
      getStartingAssociations(`/super_admin/content_management/sections/starting_associations?section_id=${selected_value_id}`, false);
      // if we're on the procedures create/edit we also need to persist the section dropdown selection despite it being a different html selector
      // we also need to make sure the category selector shows after validation error
      if ($('#new_edit_procedure').length > 0) {
        // get html section dropdown and populate it
        section_selection = $('select#Section');
        section_selection.val(selected_value_id);
      }
    } else {
      $('#contents_membership_table').hide();
      $('#table_helper_text').hide();
    }
  }
}

function hideOtherColumns(section_id) {
  $('#contents_membership_table').show();
  $('#table_helper_text').show();
  // any header with an id that isn't the section_id passed in or 'constant' - hide
  $('#contents_membership_table th:not(#sec_' + section_id + '):not(#constant)').each(function() {
    $(this).hide();
  });
  // show the header of the selected section
  $('#contents_membership_table th#sec_' + section_id).show();
  // any column cell that doesn't have a class of the section id passed into the function - hide
  $('#contents_membership_table td:not(.sec_' + section_id + '):not(#constant)').each(function() {
    $(this).hide();
  });
  // show the column cells of the selected section
  $('#contents_membership_table td.sec_' + section_id).show();
}

// this function is used to show a popup on checkbox assignment if the membership template being
// assigned to doesn't have a parent association to the content being assigned
window.checkRelation = function(checkbox) {
  // we have the mt.id at this point
  // grab the content and id from the mt_relationship_lookup by checking what the keys in the hash are prefixed with
  // since the lookup is populated based on only 1 piece of content, they will all start section_6 etc
  key = Object.keys(mt_relationship_lookup)[0].split('_',2)[0] + "_" + Object.keys(mt_relationship_lookup)[0].split('_',2)[1] + "_" + checkbox.id
  // checking singular
  if (mt_relationship_lookup[key] == 0 && $(checkbox).prop('checked') == true) {
    let user_feedback = confirm("Are you sure? This will create a relationship with the contents parent too.");
    if (user_feedback) {
      selectMultiple(checkbox);
    } else {
      $(checkbox).prop('checked', false);
    }
  // unchecking singular
  } else if (mt_relationship_lookup[key] == 0 && $(checkbox).prop('checked') == false) {
    selectMultiple(checkbox);
  }
  let all_selected = false;
  // if checked assign all
  if (!checkbox.id.startsWith('mt_') && $(checkbox).prop('checked') == true) {
    for (let i in mt_relationship_lookup) {
      // look for any mt in mt lookup that doesn't have a relationship with the current section i.e. == 0
      if (mt_relationship_lookup[i] == 0) {
        let user_feedback = confirm("Please note: \nOne or more of the membership templates do not have an association with this contents parent, assigning will create this association.");
        // if they click OK
        if (user_feedback) {
          selectAll(checkbox);
          all_selected = true;
        // if they click cancel
        } else {
          $(checkbox).prop('checked', false);
        }
        // make sure there's only one occurence of this popup
        break;
      }
    }
    // separate selectAll needed for if no zeroes are found in mt_lookup
    if (!all_selected) {
      selectAll(checkbox);
    }
  // unchecking assign all
  } else if (!checkbox.id.startsWith('mt_') && $(checkbox).prop('checked') == false) {
    selectAll(checkbox);
  }
}

// function to check what is already assigned on load - used for more efficient assignments and checking
function getStartingAssignments() {
  mt_starting_assignments = [];
  $(':checkbox[id^="mt_"]').each(function() {
    if (($(this).prop('checked') == true)) {
      item = {};
      item[$(this).attr('id').split("_")[1]] = 1
      mt_starting_assignments.push(item);
    } else if (($(this).prop('indeterminate') == true) && ($(this).prop('checked') == false)) {
      item = {};
      item[$(this).attr('id').split("_")[1]] = 2
      mt_starting_assignments.push(item);
    } else if (($(this).prop('indeterminate') == false) && ($(this).prop('checked') == false)) {
      item = {};
      item[$(this).attr('id').split("_")[1]] = 0
      mt_starting_assignments.push(item);
    }
  });
}

// get the difference between two javascript objects
// situations we care about:
// not checked in A but checked in B -> new full assignment
// indeterminate in A but checked in B -> new full assignment (fill in the missing pieces)
// indeterminate in A but not checked in B -> remove all remaining content
// checked in A but not checked in B -> newly unassigned all content
function diff(obj1, obj2) {
  result = {};
  for (let i in obj1) {
    let val1 = obj1[i];
    let val2 = obj2[i];
    let left;
    let right;
    for (let j in val1) {
      left = val1[j];
    }
    for (let k in val2) {
      right = val2[k];
    }
    if (left !== right) {
      result[i] = obj2[i];
    }
  }
  return result;
}

window.contentAssignmentModal = function(mt) {
  $('#contents_assignment_modal').addClass("is-active");
  // reset the title - looks nicer for opening multiple modals
  $('#contents_assignment_modal').find('.modal-card-title').text(`Content Assignment for: `);
  // find all divs in the modal, nuke them and replace them with the default no association red cross
  assigned_divs = $('#contents_assignment_modal').find('div[id^="section_"],div[id^="category_"],div[id^="procedure"]');
  assigned_divs.empty();
  assigned_divs.append(`<span class='icon is-medium has-text-danger'><i class='far fa-times-circle fa-lg'></i></span>`);
  // show spinner
  $(".loading-overlay").show();
  // make ajax request for associations between the content and the membership template
  $.ajax({
    url: `/super_admin/content_management/sections/content_assignment_modal`,
    method: "POST",
    dataType: "json",
    data: {mt},
    async: true,
    success: function (response) {
      // update modal title
      title = response["data"][0]["name"];
      if (title.length > 25) {
        title = title.substring(0,25) + "...";
      }
      $('#contents_assignment_modal').find('.modal-card-title').text(`Content Assignment for: ${title}`);
      // go through all associations returned from the ajax request and update the symbols in the modal table
      for (const [key, value] of Object.entries(response["data"][1])) {
        if (value == 1) {
          $('#contents_assignment_modal').find('#' + key).empty();
          $('#contents_assignment_modal').find('#' + key).append(`<span class='icon is-medium has-text-success'><i class='far fa-check-circle fa-lg'></i></span>`);
        } else {
          $('#contents_assignment_modal').find('#' + key).empty();
          $('#contents_assignment_modal').find('#' + key).append(`<span class='icon is-medium has-text-info'><i class='far fa-minus-square fa-lg'></i></span>`);
        }
      }
    },
    complete: function (response) {
      // hide spinner
      $(".loading-overlay").hide();
    }
  });
}

window.showRevisionNotesModal = function() {
  $('#revision_notes_modal').addClass("is-active");
}

// ajax request to return all the associations between all sections and all membership templates
// populates the table with icons based on what the values are for each association - shows spinner while doing this
// also uses the returned data to populate section checkboxes on edit
function getStartingAssociations(url, async) {
  $.ajax({
    url: url,
    method: "POST",
    dataType: "json",
    data: {},
    async: async,
    success: function (response) {
      mt_relationship_lookup = response['data'][0];
      // loop through the response data to discern where the blue and green ticks should go, else put a red cross
      for (const [key, value] of Object.entries(response["data"][0])) {
        // we need to empty the div beforehand or repeated section selections will mean multiple spans get added
        if (value == 1) {
          $('#' + key).empty();
          $('#' + key).append(`<span class='icon is-medium has-text-success'><i class='far fa-check-circle fa-lg'></i></span>`);
        } else if (value == 2) {
          $('#' + key).empty();
          $('#' + key).append(`<span class='icon is-medium has-text-info'><i class='far fa-minus-square fa-lg'></i></span>`);
        } else {
          $('#' + key).empty();
          $('#' + key).append("<span class='icon is-medium has-text-danger'><i class='far fa-times-circle fa-lg'></i></span>");
        }
      }
      // the below statement uses what is returned in the ajax request to also populate the checkboxes in the edit form for sections
      if ($('.edit_section').length > 0) {
        $(':checkbox[id^="mt_"]').each(function() {
          section = $('#section_id').val();
          membership_template = $(this).attr('id');
          key = section + "_" + membership_template;
          if (response['data'][0][key] == 1) {
            $(this).prop('checked', true);
          } else if (response['data'][0][key] == 2) {
            $(this).prop('indeterminate', true);
          } else {
            $(this).prop('checked', false);
          }
        });
      }
    },
    complete: function (response) {
      $('.table-loading-overlay').hide();
      // getStartingAssignments() called here as the starting point is after everything has been populated
      getStartingAssignments();
      validationRepopulation();
    }
  });
}

// function to get all associations between all categories and all membership templates
// the returned data is used to populate the checkboxes on the category edit form
function categoryStartingAssociations() {
  $('.table-loading-overlay').show();
  category = $('#category_id').val();
  $.ajax({
    url: `/super_admin/content_management/categories/category_starting_associations`,
    method: "POST",
    dataType: "json",
    data: {category},
    async: true,
    success: function (response) {
      cat_starting_assignments = response['data'][0];
      $(':checkbox[id^="mt_"]').each(function() {
        membership_template = $(this).attr('id');
        key = category + "_" + membership_template;
        if (response['data'][0][key] == 1) {
          $(this).prop('checked', true);
        } else if (response['data'][0][key] == 2) {
          $(this).prop('indeterminate', true);
        } else {
          $(this).prop('checked', false);
        }
      });
    },
    complete: function (response) {
      $('.table-loading-overlay').hide();
      // getStartingAssignments() called here as the starting point is after everything has been populated
      getStartingAssignments();
      validationRepopulation();
    }
  });
}
